import { computed } from 'vue'
import { useMainStore } from '@/stores/main'
import { APAM_AUTHENTICATION_DURATION } from '@/config/config-front-end'
import { APAM_AUTHENTICATION_ENABLED } from '@/config/environment-front-end'

export function useAuth() {
  const mainStore = useMainStore()

  const isAuthenticated = computed(() => {
    if (!APAM_AUTHENTICATION_ENABLED) return true

    const authTime = mainStore.app.authenticationTime
    const age = Date.now() - authTime

    return authTime !== 0 && age <= APAM_AUTHENTICATION_DURATION
  })

  return {
    isAuthenticated,
  }
}
