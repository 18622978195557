import { master } from './master'
import { tools } from './tools'
import { topics } from './topics'
//APAM_VERSION require to be in static otherwise connexion and data will be lost
export const data = {
  version: 99, // APAM_VERSION
  updated: 1588176260420,
  md5: 'c0e8d6fdbdf83bfbab531351df3c2e1e',
  ...master,
  ...tools,
  ...topics,
}
