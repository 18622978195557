<script setup lang="ts">
import { computed } from 'vue'
import { APAM_RESTRICT_USERS_TO_LOREAL } from '@/config/environment-front-end'
import { useMainStore } from '@/stores/main'
import SignInButton from '@/components/SignInButton.vue'

const mainStore = useMainStore()
const isLoaderVisible = computed(() => mainStore.loaderVisible)
</script>

<template>
  <template v-if="isLoaderVisible">
    <AnimatedLoader />
  </template>
  <template v-else>
    <div class="signin-container">
      <h2>Identification required</h2>

      <template v-if="APAM_RESTRICT_USERS_TO_LOREAL">
        <p>
          This application is restricted to users who have a Google account using a
          <em>L'Oréal</em> e-mail address.
        </p>
        <p>Example: <em>firstname.lastname</em><strong>@loreal.com</strong></p>
      </template>
      <template v-else>
        <p>This application is accessible only to users who have a Google account.</p>
      </template>

      <p>
        This application uses the Google service named
        <a href="https://developers.google.com/identity/protocols/OAuth2UserAgent">OAuth 2.0</a>.
      </p>

      <template v-if="mainStore.app.offline">
        <p>
          <strong>You are currently offline.</strong> When you get back online, please click on the
          button below to identify yourself.
        </p>
      </template>
      <template v-else>
        <p>Please click on the button below to identify yourself.</p>
      </template>

      <div class="sign-in-button">
        <SignInButton />
      </div>
    </div>
  </template>
</template>

<style scoped>
.signin-container {
  max-width: 30em;
  margin: 1em auto;
  padding: 1em;
  background-color: white;
  color: black;
}

p {
  line-height: 1.5;
  margin-bottom: 1em;
}

.sign-in-button {
  text-align: center;
  margin-top: 2em;
}

a {
  color: #1976d2;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

strong {
  font-weight: 600;
}

:deep(.sign-in-button button) {
  text-transform: uppercase;
  padding: 0px 15px;
  font-size: 14px;
  height: 28px;
}
</style>
