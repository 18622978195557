// to run it locally on developers desktop
export const APAM_ENVIRONMENT_LOCAL_VALUE = 'local'
// to deploy on https://apam-dev.e-loreal.com
export const APAM_ENVIRONMENT_DEV_VALUE = 'dev'
// to deploy on https://apam.e-loreal.com
export const APAM_ENVIRONMENT_PROD_VALUE = 'prod'

// export const APAM_ENVIRONMENT = APAM_ENVIRONMENT_LOCAL_VALUE;
// export const APAM_ENVIRONMENT = APAM_ENVIRONMENT_DEV_VALUE;
export const APAM_ENVIRONMENT = APAM_ENVIRONMENT_PROD_VALUE
export const APAM_PUB_SUB_PROJECT_ID = 'automation-status-215320'
export const APAM_PROJECT_ID = 'automation-status-215320'
export const APAM_INVENTORY_BUCKET_NAME = 'apam-inventory-prod'
export const APAM_CLIENT_ID =
  '476398162502-ngvd7fm76bk1ft2l0g10tb6dqs73qof9.apps.googleusercontent.com'
export const APAM_API_KEY = 'AIzaSyDLHJRvriMtYUvEilgix296kTSI8AI0Bn4'
export const APAM_API_SCOPE = 'https://www.googleapis.com/auth/userinfo.email email profile openid'

// Disable authentification for Google Verification due to OAuth Consent Screen
// Development : https://console.cloud.google.com/apis/credentials/consent?project=automation-status-dev
// Production : https://console.cloud.google.com/apis/credentials/consent?project=automation-status

/**
 * APAM_AUTHENTICATION_ENABLED constant exist in environment-back-end.js && environment-front-end.js
 * Please let them, have the same value
 * USE CASE: Usual dev
 *      When  APAM_AUTHENTICATION_ENABLED = true
 *        Start server using script
 *        URL  https://apam-local.e-loreal.com/
 *
 * USE CASE: service worker need or anything to be deployed alike
 *      When APAM_AUTHENTICATION_ENABLED = false
 *        Start server using cd server and npm start
 *        http://localhost:8080/
 */
export const APAM_AUTHENTICATION_ENABLED = true
// export const APAM_AUTHENTICATION_ENABLED = false;

export const APAM_RESTRICT_USERS_TO_LOREAL = true
// export const APAM_RESTRICT_USERS_TO_LOREAL = false;

//WATCH OUT If APAM_RESTRICT_REFRESH_BUTTON become true, then some methods are missing
// export const APAM_RESTRICT_REFRESH_BUTTON = true;
export const APAM_RESTRICT_REFRESH_BUTTON = false

//@deprecated ?
export const APAM_RESTRICT_DOWNLOAD_BUTTON = false

// Enable links / button to our "action plan" view:
export const APAM_ACTION_PLAN_ENABLED = true
// export const APAM_ACTION_PLAN_ENABLED = false;

export const APAM_DISPLAY_AUTHENT_LOGS = false
export const APAM_DISPLAY_FIX_LOGS = true
//This is to not reconnect the user
// export const APAM_RESTRICT_FIX_BUTTON_TO_CONNECTED = true;
export const APAM_RESTRICT_FIX_BUTTON_TO_CONNECTED = false
