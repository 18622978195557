export const master = {
  zone: [
    'Africa - Middle East',
    'Asia - Pacific',
    'Eastern Europe',
    'International',
    'Latin America',
    'North America',
    'Western Europe',
  ],
  country: [
    'Argentina',
    'Australia',
    'Austria',
    'Belgium',
    'Brazil',
    'Bulgaria',
    'Canada',
    'Central America',
    'Chile',
    'Chinese Mainland',
    'Colombia',
    'Croatia',
    'Czech Republic',
    'Denmark',
    'Ecuador',
    'Egypt',
    'Estonia',
    'Europe',
    'Finland',
    'France',
    'Germany',
    'Ghana',
    'Greece',
    'Hong Kong SAR',
    'Hungary',
    'India',
    'Indonesia',
    'International',
    'Ireland',
    'Israel',
    'Italy',
    'Japan',
    'Kenya',
    'Korea',
    'Latin America',
    'Latvia',
    'Lebanon',
    'Lithuania',
    'Malaysia',
    'Mexico',
    'Middle East',
    'Morocco',
    'Netherlands',
    'New Zealand',
    'Nigeria',
    'Norway',
    'Oman',
    'Pakistan',
    'Panama',
    'Peru',
    'Philippines',
    'Poland',
    'Portugal',
    'Romania',
    'Russia',
    'Saudi Arabia',
    'Serbia',
    'Singapore',
    'Slovakia',
    'Slovenia',
    'South Africa',
    'Spain',
    'Sweden',
    'Switzerland',
    'Taiwan Region',
    'Thailand',
    'Turkey',
    'Ukraine',
    'United Arab Emirates',
    'United Kingdom',
    'United States',
    'Uruguay',
    'Venezuela',
    'Vietnam',
  ],
  division: ['ACD', 'CORP', 'CPD', 'LPD', 'PPD'],
  brand: [
    'Acne Free',
    'Alexandre de Paris',
    'Atelier Cologne',
    'Baxter of California',
    'Biotherm',
    'Carita',
    "Carol's Daughter",
    'CeraVe',
    'Clarisonic',
    'Color&Co',
    'Colorama',
    'Corporate',
    'DIV - ACD',
    'DIV - CPD',
    'DIV - DGRD',
    'DIV - PPD',
    'Decléor',
    'Essie',
    'Garnier',
    'Giorgio Armani',
    'Helena Rubinstein',
    'House 99',
    'IT Cosmetics',
    "Kiehl's",
    'Kérastase',
    "L'Oréal Country",
    "L'Oréal Paris",
    "L'Oréal Professionnel",
    'LASCAD',
    'La Provençale Bio',
    'La Roche-Posay',
    'Lancôme',
    'Matrix',
    'Maybelline New York',
    'Mixa',
    'Mizani',
    'NYX Professional Makeup',
    'Niely Cosmeticos',
    'Pulp Riot',
    'Pureology',
    'Redken',
    'Roger & Gallet',
    'Saint-Gervais Mont Blanc',
    'Sanoflore',
    'Seed Phytonutrients',
    'Shu Uemura',
    'Shu Uemura Art of Hair',
    'SkinCeuticals',
    'Softsheen Carson',
    'Urban Decay',
    'Vichy',
    'Viktor & Rolf',
    'Vogue Cosméticos',
    'Yue Sai',
    'Yves Saint Laurent',
  ],
  framework: ['LORA', 'MBF V2', 'NGLORA', 'WSF', 'Other'],
  url: [
    'http://lounge.loreal-paris.de',
    'http://makeup.ru',
    'http://mixa.ma',
    'http://us.lorealprofessionnel.com',
    'http://www.bashartek.com',
    'http://www.beaute.fr',
    'http://www.belezaextraordinaria.com.br',
    'http://www.biotherm.com.hk',
    'http://www.episkin.com',
    'http://www.garnier.cl',
    'http://www.helenarubinstein.cn',
    'http://www.larocheposay.com.cn',
    'http://www.loreal-finance.com',
    'http://www.loreal-finance.com.cn',
    'http://www.loreal.com',
    'http://www.loreal.com.ar',
    'http://www.loreal.com.ph',
    'http://www.loreal.com.tr',
    'http://www.loreal.ma',
    'http://www.loreal.pe',
    'http://www.lorealchina.com',
    'http://www.lorealprofessionnel.com.br',
    'http://www.lorealuruguay.uy',
    'http://www.narta.fr',
    'http://www.nihon-loreal.co.jp',
    'http://www.nihon-loreal.jp',
    'http://www.tudosobremake.com.br',
    'http://www.vichy.com.cn/member',
    'https://ae.kerastase.com/en/',
    'https://afrique.lorealparis.com',
    'https://afrique.maybelline.com',
    'https://armanibeauty.com.ru',
    'https://biotherm.dk',
    'https://biotherm.no',
    'https://biotherm.ru',
    'https://biotherm.se',
    'https://careers.loreal.com',
    'https://cerave.ru',
    'https://de.mixa.com',
    'https://helenarubinstein.com.hk',
    'https://helenarubinstein.com/ru/',
    'https://kerastase.ru',
    'https://kiehls.co.id',
    'https://kiehls.com.vn',
    'https://kiehls.pt',
    'https://kiehls.ru',
    'https://kiehls.ua',
    'https://lancome.ru',
    'https://lancome.ua',
    'https://laprovencale.bio',
    'https://loja.segredosdesalao.com.br',
    'https://loreal-paris.co.il',
    'https://loreal-paris.ua',
    'https://lorealprofessionnel.ru',
    'https://makyaj.com',
    'https://maybelline.ua',
    'https://mixa.ua',
    'https://nyxcosmetic.ru',
    'https://nyxcosmetics.ro',
    'https://nyxcosmeticsbaltics.com',
    'https://om.kerastase.com',
    'https://parfums.viktor-rolf.com/fr',
    'https://pureology.com.au',
    'https://redken.ru',
    'https://sa.kerastase.com',
    'https://sacsirlari.com',
    'https://saint-gervais-mont-blanc.com',
    'https://shuuemura.ru',
    'https://skin.ru',
    'https://urbandecay.ru',
    'https://us.viktor-rolf.com',
    'https://vichy-th.com',
    'https://vichy.com.vn',
    'https://www.acnefree.com',
    'https://www.alexandredeparis.jp',
    'https://www.armani-beauty.ca',
    'https://www.armanibeauty.co.uk',
    'https://www.armanibeauty.com',
    'https://www.armanibeauty.com.my',
    'https://www.armanibeauty.de',
    'https://www.armanibeauty.es',
    'https://www.armanibeauty.fr',
    'https://www.armanibeauty.it',
    'https://www.armanibeauty.jp',
    'https://www.asraralmakyaj.com',
    'https://www.ateliercologne.co.kr',
    'https://www.ateliercologne.com',
    'https://www.ateliercologne.eu',
    'https://www.ateliercologne.fr',
    'https://www.ateliercologne.hk',
    'https://www.baxterofcalifornia.com',
    'https://www.biotherm-usa.com',
    'https://www.biotherm.ca',
    'https://www.biotherm.co.kr',
    'https://www.biotherm.co.th',
    'https://www.biotherm.com',
    'https://www.biotherm.com.cn',
    'https://www.biotherm.com.my',
    'https://www.biotherm.com.tw',
    'https://www.biotherm.de',
    'https://www.biotherm.es',
    'https://www.biotherm.fr',
    'https://www.biotherm.it',
    'https://www.cadum.fr',
    'https://www.carita.com/de-DE',
    'https://www.carita.com/en-UK',
    'https://www.carita.com/es-ES',
    'https://www.carita.com/fr-FR',
    'https://www.carita.com/it-IT',
    'https://www.carita.com/nl-NL',
    'https://www.carolsdaughter.com',
    'https://www.cerave-centroamerica.com',
    'https://www.cerave.be',
    'https://www.cerave.bg',
    'https://www.cerave.ca',
    'https://www.cerave.cl',
    'https://www.cerave.cn',
    'https://www.cerave.co.th',
    'https://www.cerave.co.uk',
    'https://www.cerave.com',
    'https://www.cerave.com.au',
    'https://www.cerave.com.br',
    'https://www.cerave.com.tr',
    'https://www.cerave.com.tw',
    'https://www.cerave.cz',
    'https://www.cerave.de',
    'https://www.cerave.es',
    'https://www.cerave.fi',
    'https://www.cerave.fr',
    'https://www.cerave.gr',
    'https://www.cerave.hk',
    'https://www.cerave.hu',
    'https://www.cerave.it',
    'https://www.cerave.mx',
    'https://www.cerave.no',
    'https://www.cerave.pe',
    'https://www.cerave.pl',
    'https://www.cerave.pt',
    'https://www.cerave.ro',
    'https://www.cerave.se',
    'https://www.cerave.sk',
    'https://www.cerave.uy',
    'https://www.clarisonic.ca',
    'https://www.clarisonic.cn',
    'https://www.clarisonic.co.uk',
    'https://www.clarisonic.com',
    'https://www.coloramaesmaltes.com.br',
    'https://www.colorandco.com',
    'https://www.cosmeticosvogue-centroamerica.com',
    'https://www.cosmeticosvogue.cl',
    'https://www.cosmeticosvogue.com.ar',
    'https://www.cosmeticosvogue.com.co',
    'https://www.cosmeticosvogue.com.mx',
    'https://www.cosmeticosvogue.pe',
    'https://www.cosmeticosvogue.uy',
    'https://www.darkandlovely.co.ke',
    'https://www.darkandlovely.co.za',
    'https://www.darkandlovely.com.gh',
    'https://www.darkandlovely.com.ng',
    'https://www.decleor.co.uk',
    'https://www.decleor.com',
    'https://www.decleor.de',
    'https://www.decleor.es',
    'https://www.decleor.fr',
    'https://www.decleor.nl',
    'https://www.dermablend.ca',
    'https://www.dermablend.com',
    'https://www.dermaclub.com.br',
    'https://www.es.maybelline.com',
    'https://www.essie.ca',
    'https://www.essie.ch',
    'https://www.essie.co.uk',
    'https://www.essie.com',
    'https://www.essie.com.au',
    'https://www.essie.de',
    'https://www.essie.es',
    'https://www.essie.fr',
    'https://www.essie.gr',
    'https://www.essie.jp',
    'https://www.fiquediva.com.br',
    'https://www.fondationloreal.com',
    'https://www.franckprovost-expert.com',
    'https://www.franckprovost-expert.it',
    'https://www.garnier-be.com',
    'https://www.garnier-centroamerica.com',
    'https://www.garnier-me.com',
    'https://www.garnier.ca',
    'https://www.garnier.ch',
    'https://www.garnier.co.id',
    'https://www.garnier.co.il',
    'https://www.garnier.co.th',
    'https://www.garnier.co.uk',
    'https://www.garnier.co.za',
    'https://www.garnier.com.ar',
    'https://www.garnier.com.au',
    'https://www.garnier.com.br',
    'https://www.garnier.com.co',
    'https://www.garnier.com.mx',
    'https://www.garnier.com.my',
    'https://www.garnier.com.pe',
    'https://www.garnier.com.ph',
    'https://www.garnier.com.tr',
    'https://www.garnier.com.uy',
    'https://www.garnier.de',
    'https://www.garnier.dk',
    'https://www.garnier.es',
    'https://www.garnier.fi',
    'https://www.garnier.fr',
    'https://www.garnier.gr',
    'https://www.garnier.in',
    'https://www.garnier.it',
    'https://www.garnier.no',
    'https://www.garnier.pl',
    'https://www.garnier.pt',
    'https://www.garnier.ro',
    'https://www.garnier.ru',
    'https://www.garnier.se',
    'https://www.garnier.ua',
    'https://www.garnierusa.com',
    'https://www.garnierzorgvoorjezelf.nl',
    'https://www.giorgioarmanibeauty-usa.com',
    'https://www.giorgioarmanibeauty.cn',
    'https://www.giorgioarmanibeauty.co.kr',
    'https://www.giorgioarmanibeauty.com.au',
    'https://www.giorgioarmanibeauty.com.hk',
    'https://www.giorgioarmanibeauty.com.tw',
    'https://www.hair.com',
    'https://www.helenarubinstein.com.tw',
    'https://www.helenarubinstein.com/es/',
    'https://www.helenarubinstein.com/fr/',
    'https://www.helenarubinstein.com/int/',
    'https://www.helenarubinstein.com/it/',
    'https://www.helenarubinstein.com/nl/',
    'https://www.helenarubinstein.jp',
    'https://www.house99.ca',
    'https://www.house99.co.uk',
    'https://www.house99.de',
    'https://www.house99.es',
    'https://www.house99.fr',
    'https://www.house99.it',
    'https://www.house99davidbeckham-usa.com',
    'https://www.institutoloreal.com.br',
    'https://www.itcosmetics.ca',
    'https://www.itcosmetics.co.uk',
    'https://www.itcosmetics.com',
    'https://www.itcosmetics.de',
    'https://www.itcosmetics.fr',
    'https://www.kerastase-centroamerica.com',
    'https://www.kerastase-usa.com',
    'https://www.kerastase.at',
    'https://www.kerastase.be',
    'https://www.kerastase.ca',
    'https://www.kerastase.ch',
    'https://www.kerastase.cl',
    'https://www.kerastase.co.id',
    'https://www.kerastase.co.il',
    'https://www.kerastase.co.kr',
    'https://www.kerastase.co.uk',
    'https://www.kerastase.com',
    'https://www.kerastase.com.ar',
    'https://www.kerastase.com.au',
    'https://www.kerastase.com.br',
    'https://www.kerastase.com.co',
    'https://www.kerastase.com.hk',
    'https://www.kerastase.com.mx',
    'https://www.kerastase.com.my',
    'https://www.kerastase.com.pl',
    'https://www.kerastase.com.tr',
    'https://www.kerastase.com.tw',
    'https://www.kerastase.de',
    'https://www.kerastase.dk',
    'https://www.kerastase.es',
    'https://www.kerastase.fi',
    'https://www.kerastase.fr',
    'https://www.kerastase.gr',
    'https://www.kerastase.in',
    'https://www.kerastase.it',
    'https://www.kerastase.jp',
    'https://www.kerastase.nl',
    'https://www.kerastase.no',
    'https://www.kerastase.pe',
    'https://www.kerastase.pt',
    'https://www.kerastase.se',
    'https://www.kerastase.uy',
    'https://www.kiehls.ae',
    'https://www.kiehls.at',
    'https://www.kiehls.be',
    'https://www.kiehls.ca',
    'https://www.kiehls.ch',
    'https://www.kiehls.cl',
    'https://www.kiehls.co.kr',
    'https://www.kiehls.co.th',
    'https://www.kiehls.co.uk',
    'https://www.kiehls.com',
    'https://www.kiehls.com.au',
    'https://www.kiehls.com.cn',
    'https://www.kiehls.com.hk',
    'https://www.kiehls.com.mx',
    'https://www.kiehls.com.ph',
    'https://www.kiehls.com.sg',
    'https://www.kiehls.com.tr',
    'https://www.kiehls.com.tw',
    'https://www.kiehls.cz',
    'https://www.kiehls.de',
    'https://www.kiehls.dk',
    'https://www.kiehls.es',
    'https://www.kiehls.fr',
    'https://www.kiehls.it',
    'https://www.kiehls.jp',
    'https://www.kiehls.nl',
    'https://www.kiehls.no',
    'https://www.kiehls.pl',
    'https://www.kiehls.ro',
    'https://www.kiehls.sa',
    'https://www.kiehls.se',
    'https://www.kiehlstimes.com.my',
    'https://www.lancome-me.com',
    'https://www.lancome-usa.com',
    'https://www.lancome.ca',
    'https://www.lancome.cl',
    'https://www.lancome.co.il',
    'https://www.lancome.co.kr',
    'https://www.lancome.co.th',
    'https://www.lancome.co.uk',
    'https://www.lancome.com.ar',
    'https://www.lancome.com.au',
    'https://www.lancome.com.br',
    'https://www.lancome.com.cn',
    'https://www.lancome.com.hk',
    'https://www.lancome.com.mx',
    'https://www.lancome.com.my',
    'https://www.lancome.com.sg',
    'https://www.lancome.com.tr',
    'https://www.lancome.com.tw',
    'https://www.lancome.de',
    'https://www.lancome.dk',
    'https://www.lancome.es',
    'https://www.lancome.fr',
    'https://www.lancome.it',
    'https://www.lancome.jp',
    'https://www.lancome.no',
    'https://www.lancome.pl',
    'https://www.lancome.se',
    'https://www.lancome.vn',
    'https://www.laroche-posay-ve.com',
    'https://www.laroche-posay.at',
    'https://www.laroche-posay.be/fr/',
    'https://www.laroche-posay.bg',
    'https://www.laroche-posay.ca',
    'https://www.laroche-posay.ch',
    'https://www.laroche-posay.cl',
    'https://www.laroche-posay.co',
    'https://www.laroche-posay.co.il',
    'https://www.laroche-posay.co.uk',
    'https://www.laroche-posay.com',
    'https://www.laroche-posay.com.ar',
    'https://www.laroche-posay.com.au',
    'https://www.laroche-posay.com.br',
    'https://www.laroche-posay.com.hr',
    'https://www.laroche-posay.com.mx',
    'https://www.laroche-posay.com.tr',
    'https://www.laroche-posay.com.uy',
    'https://www.laroche-posay.cz',
    'https://www.laroche-posay.dk',
    'https://www.laroche-posay.es',
    'https://www.laroche-posay.fi',
    'https://www.laroche-posay.fr',
    'https://www.laroche-posay.hk/cn',
    'https://www.laroche-posay.ie',
    'https://www.laroche-posay.jp',
    'https://www.laroche-posay.ma',
    'https://www.laroche-posay.me',
    'https://www.laroche-posay.nl',
    'https://www.laroche-posay.no',
    'https://www.laroche-posay.pe',
    'https://www.laroche-posay.pl',
    'https://www.laroche-posay.pt',
    'https://www.laroche-posay.ro',
    'https://www.laroche-posay.rs',
    'https://www.laroche-posay.ru',
    'https://www.laroche-posay.se',
    'https://www.laroche-posay.sg',
    'https://www.laroche-posay.si',
    'https://www.laroche-posay.sk',
    'https://www.laroche-posay.ua',
    'https://www.laroche-posay.us',
    'https://www.laroche-posayca.com',
    'https://www.larocheposay-lb.com',
    'https://www.larocheposay-th.com',
    'https://www.larocheposay.co.kr',
    'https://www.larocheposay.de',
    'https://www.larocheposay.eg',
    'https://www.larocheposay.gr',
    'https://www.larocheposay.hu',
    'https://www.larocheposay.it',
    'https://www.larocheposay.vn',
    'https://www.loreal-paris-me.com',
    'https://www.loreal-paris.be',
    'https://www.loreal-paris.co.id',
    'https://www.loreal-paris.co.th',
    'https://www.loreal-paris.co.uk',
    'https://www.loreal-paris.co.za',
    'https://www.loreal-paris.com.br',
    'https://www.loreal-paris.com.hk',
    'https://www.loreal-paris.com.lb',
    'https://www.loreal-paris.com.mx',
    'https://www.loreal-paris.com.pk',
    'https://www.loreal-paris.com.sg',
    'https://www.loreal-paris.de',
    'https://www.loreal-paris.ee',
    'https://www.loreal-paris.eg',
    'https://www.loreal-paris.es',
    'https://www.loreal-paris.fr',
    'https://www.loreal-paris.it',
    'https://www.loreal-paris.lt',
    'https://www.loreal-paris.lv',
    'https://www.loreal-paris.ma',
    'https://www.loreal-paris.nl',
    'https://www.loreal-paris.ru',
    'https://www.loreal-professionnel.jp',
    'https://www.loreal.at',
    'https://www.loreal.ca',
    'https://www.loreal.ch',
    'https://www.loreal.cl',
    'https://www.loreal.co',
    'https://www.loreal.co.id',
    'https://www.loreal.co.il',
    'https://www.loreal.co.in',
    'https://www.loreal.co.kr',
    'https://www.loreal.co.uk',
    'https://www.loreal.co.za',
    'https://www.loreal.com.au',
    'https://www.loreal.com.br',
    'https://www.loreal.com.pa',
    'https://www.loreal.com.ru',
    'https://www.loreal.com.tw',
    'https://www.loreal.cz',
    'https://www.loreal.de',
    'https://www.loreal.es',
    'https://www.loreal.fr',
    'https://www.loreal.hk',
    'https://www.loreal.hu',
    'https://www.loreal.it',
    'https://www.loreal.mx',
    'https://www.loreal.my',
    'https://www.loreal.nz',
    'https://www.loreal.pl',
    'https://www.loreal.ro',
    'https://www.loreal.sg',
    'https://www.loreal.ua',
    'https://www.loreal.vn',
    'https://www.lorealparis.ca',
    'https://www.lorealparis.ch',
    'https://www.lorealparis.cl',
    'https://www.lorealparis.co.in',
    'https://www.lorealparis.co.kr',
    'https://www.lorealparis.com.ar',
    'https://www.lorealparis.com.au',
    'https://www.lorealparis.com.cn',
    'https://www.lorealparis.com.co',
    'https://www.lorealparis.com.my',
    'https://www.lorealparis.com.pa',
    'https://www.lorealparis.com.ph',
    'https://www.lorealparis.com.tr',
    'https://www.lorealparis.com.tw',
    'https://www.lorealparis.com.vn',
    'https://www.lorealparis.cz',
    'https://www.lorealparis.dk',
    'https://www.lorealparis.fi',
    'https://www.lorealparis.gr',
    'https://www.lorealparis.hu',
    'https://www.lorealparis.no',
    'https://www.lorealparis.pe',
    'https://www.lorealparis.pl',
    'https://www.lorealparis.pt',
    'https://www.lorealparis.ro',
    'https://www.lorealparis.se',
    'https://www.lorealparis.sk',
    'https://www.lorealparis.uy',
    'https://www.lorealparisjapan.jp',
    'https://www.lorealparisusa.com',
    'https://www.lorealprofessionnel-centroamerica.com',
    'https://www.lorealprofessionnel-me.com',
    'https://www.lorealprofessionnel.be',
    'https://www.lorealprofessionnel.ca',
    'https://www.lorealprofessionnel.ch',
    'https://www.lorealprofessionnel.cl',
    'https://www.lorealprofessionnel.co',
    'https://www.lorealprofessionnel.co.kr',
    'https://www.lorealprofessionnel.co.uk',
    'https://www.lorealprofessionnel.com.ar',
    'https://www.lorealprofessionnel.com.au',
    'https://www.lorealprofessionnel.com.mx',
    'https://www.lorealprofessionnel.com/int/',
    'https://www.lorealprofessionnel.de',
    'https://www.lorealprofessionnel.es',
    'https://www.lorealprofessionnel.fr',
    'https://www.lorealprofessionnel.gr',
    'https://www.lorealprofessionnel.id',
    'https://www.lorealprofessionnel.in',
    'https://www.lorealprofessionnel.it',
    'https://www.lorealprofessionnel.nl',
    'https://www.lorealprofessionnel.pe',
    'https://www.lorealprofessionnel.pt',
    'https://www.lorealprofessionnel.sg',
    'https://www.lorealprofessionnel.uy',
    'https://www.lorealthailand.com',
    'https://www.lorealusa.com',
    'https://www.lrp.com.tw',
    'https://www.makeup.com',
    'https://www.matrix.com',
    'https://www.matrix.ru',
    'https://www.matrixhaircare.co.uk',
    'https://www.matrixprofessional.be',
    'https://www.matrixprofessional.ca',
    'https://www.matrixprofessional.cl',
    'https://www.matrixprofessional.co.id',
    'https://www.matrixprofessional.co.nz',
    'https://www.matrixprofessional.com',
    'https://www.matrixprofessional.com.ar',
    'https://www.matrixprofessional.com.au',
    'https://www.matrixprofessional.com.mx',
    'https://www.matrixprofessional.de',
    'https://www.matrixprofessional.es',
    'https://www.matrixprofessional.fr',
    'https://www.matrixprofessional.in',
    'https://www.matrixprofessional.it',
    'https://www.maybelline-ma.com',
    'https://www.maybelline-me.com',
    'https://www.maybelline.be',
    'https://www.maybelline.ca',
    'https://www.maybelline.ch',
    'https://www.maybelline.cl',
    'https://www.maybelline.co',
    'https://www.maybelline.co.id',
    'https://www.maybelline.co.il',
    'https://www.maybelline.co.in',
    'https://www.maybelline.co.jp',
    'https://www.maybelline.co.kr',
    'https://www.maybelline.co.th',
    'https://www.maybelline.co.uk',
    'https://www.maybelline.co.za',
    'https://www.maybelline.com',
    'https://www.maybelline.com.ar',
    'https://www.maybelline.com.au',
    'https://www.maybelline.com.br',
    'https://www.maybelline.com.hk',
    'https://www.maybelline.com.lb',
    'https://www.maybelline.com.mx',
    'https://www.maybelline.com.my',
    'https://www.maybelline.com.ph',
    'https://www.maybelline.com.pk',
    'https://www.maybelline.com.ru',
    'https://www.maybelline.com.sg',
    'https://www.maybelline.com.tr',
    'https://www.maybelline.com.tw',
    'https://www.maybelline.de',
    'https://www.maybelline.dk',
    'https://www.maybelline.eg',
    'https://www.maybelline.es',
    'https://www.maybelline.fi',
    'https://www.maybelline.fr',
    'https://www.maybelline.gr',
    'https://www.maybelline.it',
    'https://www.maybelline.ma',
    'https://www.maybelline.nl',
    'https://www.maybelline.no',
    'https://www.maybelline.pe',
    'https://www.maybelline.pl',
    'https://www.maybelline.pt',
    'https://www.maybelline.ro',
    'https://www.maybelline.se',
    'https://www.maybelline.uy',
    'https://www.maybelline.vn',
    'https://www.maybellinechina.com',
    'https://www.menexpert.de',
    'https://www.mixa.fr',
    'https://www.mixa.hu',
    'https://www.mixa.sk',
    'https://www.mixafrancie.cz',
    'https://www.mizani.com',
    'https://www.mydermacenter.pl',
    'https://www.nyxcosmetics-centroamerica.com',
    'https://www.nyxcosmetics-nordics.com',
    'https://www.nyxcosmetics.be',
    'https://www.nyxcosmetics.ca',
    'https://www.nyxcosmetics.ch',
    'https://www.nyxcosmetics.cl',
    'https://www.nyxcosmetics.co.uk',
    'https://www.nyxcosmetics.com',
    'https://www.nyxcosmetics.com.au',
    'https://www.nyxcosmetics.com.mx',
    'https://www.nyxcosmetics.com.tr',
    'https://www.nyxcosmetics.com.tw',
    'https://www.nyxcosmetics.de',
    'https://www.nyxcosmetics.ec',
    'https://www.nyxcosmetics.es',
    'https://www.nyxcosmetics.fr',
    'https://www.nyxcosmetics.gr',
    'https://www.nyxcosmetics.hk',
    'https://www.nyxcosmetics.it',
    'https://www.nyxcosmetics.jp',
    'https://www.nyxcosmetics.pe',
    'https://www.nyxprofessionalmakeup.pl',
    'https://www.pulpriothair.com',
    'https://www.pureology.ca',
    'https://www.pureology.co.uk',
    'https://www.pureology.com',
    'https://www.pureology.nz',
    'https://www.redken-centroamerica.com',
    'https://www.redken.be',
    'https://www.redken.ca',
    'https://www.redken.ch',
    'https://www.redken.cl',
    'https://www.redken.co.uk',
    'https://www.redken.com',
    'https://www.redken.com.au',
    'https://www.redken.com.br',
    'https://www.redken.com.es',
    'https://www.redken.com.mx',
    'https://www.redken.cz',
    'https://www.redken.de',
    'https://www.redken.dk',
    'https://www.redken.fi',
    'https://www.redken.fr',
    'https://www.redken.hu',
    'https://www.redken.it',
    'https://www.redken.no',
    'https://www.redken.pe',
    'https://www.redken.pt',
    'https://www.redken.se',
    'https://www.redken.uy',
    'https://www.redkennyc.pl',
    'https://www.roger-gallet.co.uk',
    'https://www.roger-gallet.com/de-de/',
    'https://www.roger-gallet.com/el-gr/',
    'https://www.roger-gallet.com/es-es/',
    'https://www.roger-gallet.com/fr-fr/',
    'https://www.roger-gallet.com/it-it/',
    'https://www.roger-gallet.com/nl-be/',
    'https://www.roger-gallet.com/pt-pt/',
    'https://www.roger-gallet.ie',
    'https://www.roger-gallet.jp',
    'https://www.saloncentric.com',
    'https://www.salonsecret.es',
    'https://www.salonsecret.ru',
    'https://www.sanoflore.fr',
    'https://www.seedphytonutrients.com',
    'https://www.segredosdesalao.com.br',
    'https://www.shuuemura-usa.com',
    'https://www.shuuemura.ca',
    'https://www.shuuemura.com.cn',
    'https://www.shuuemura.com.hk',
    'https://www.shuuemura.com.my',
    'https://www.shuuemura.com.sg',
    'https://www.shuuemura.com.tw',
    'https://www.shuuemura.com.vn',
    'https://www.shuuemura.jp',
    'https://www.shuuemuraartofhair-usa.com',
    'https://www.shuuemurath.com',
    'https://www.skincare.com',
    'https://www.skinceuticals-kr.com',
    'https://www.skinceuticals-latam.com',
    'https://www.skinceuticals-lebanon.com',
    'https://www.skinceuticals-za.com',
    'https://www.skinceuticals.be/fr',
    'https://www.skinceuticals.ca',
    'https://www.skinceuticals.ch',
    'https://www.skinceuticals.co.uk',
    'https://www.skinceuticals.com',
    'https://www.skinceuticals.com.au',
    'https://www.skinceuticals.com.br',
    'https://www.skinceuticals.com.cn',
    'https://www.skinceuticals.com.hk',
    'https://www.skinceuticals.com.mx',
    'https://www.skinceuticals.com.tr',
    'https://www.skinceuticals.de',
    'https://www.skinceuticals.dk',
    'https://www.skinceuticals.es',
    'https://www.skinceuticals.fr',
    'https://www.skinceuticals.gr',
    'https://www.skinceuticals.it',
    'https://www.skinceuticals.nl',
    'https://www.skinceuticals.no',
    'https://www.skinceuticals.pt',
    'https://www.skinceuticals.ru',
    'https://www.skinceuticals.se',
    'https://www.skinceuticals.sg',
    'https://www.softsheen-carson.com',
    'https://www.st-gervais-mont-blanc.com.cn',
    'https://www.thermes-saint-gervais.com',
    'https://www.tupielytu.es',
    'https://www.urban-decay.dk',
    'https://www.urban-decay.nl',
    'https://www.urbandecay.be',
    'https://www.urbandecay.ca',
    'https://www.urbandecay.cl',
    'https://www.urbandecay.co.kr',
    'https://www.urbandecay.co.th',
    'https://www.urbandecay.co.uk',
    'https://www.urbandecay.co.za',
    'https://www.urbandecay.com',
    'https://www.urbandecay.com.hk',
    'https://www.urbandecay.com.my',
    'https://www.urbandecay.cz',
    'https://www.urbandecay.de',
    'https://www.urbandecay.es',
    'https://www.urbandecay.fr',
    'https://www.urbandecay.it',
    'https://www.urbandecay.mx',
    'https://www.urbandecay.no',
    'https://www.urbandecay.se',
    'https://www.ushuaia-beaute.fr',
    'https://www.venteaupersonnel.fr',
    'https://www.vichy-lb.com',
    'https://www.vichy-me.com',
    'https://www.vichy.at',
    'https://www.vichy.be',
    'https://www.vichy.bg',
    'https://www.vichy.ca',
    'https://www.vichy.ch',
    'https://www.vichy.cl',
    'https://www.vichy.co.il',
    'https://www.vichy.co.uk',
    'https://www.vichy.co.za',
    'https://www.vichy.com.br',
    'https://www.vichy.com.co',
    'https://www.vichy.com.mx',
    'https://www.vichy.com.tr',
    'https://www.vichy.com.tw',
    'https://www.vichy.com.uy',
    'https://www.vichy.cz',
    'https://www.vichy.de',
    'https://www.vichy.dk',
    'https://www.vichy.eg',
    'https://www.vichy.es',
    'https://www.vichy.fi',
    'https://www.vichy.fr',
    'https://www.vichy.gr',
    'https://www.vichy.hk',
    'https://www.vichy.hr',
    'https://www.vichy.hu',
    'https://www.vichy.ie',
    'https://www.vichy.it',
    'https://www.vichy.ma',
    'https://www.vichy.nl',
    'https://www.vichy.no',
    'https://www.vichy.pl',
    'https://www.vichy.pt',
    'https://www.vichy.ro',
    'https://www.vichy.rs',
    'https://www.vichy.se',
    'https://www.vichy.sg',
    'https://www.vichy.si',
    'https://www.vichy.sk',
    'https://www.vichy.ua',
    'https://www.vichyargentina.com.ar',
    'https://www.vichyca.com',
    'https://www.vichyconsult.ru',
    'https://www.vichyusa.com',
    'https://www.yslb.jp',
    'https://www.yslbeauty.ca',
    'https://www.yslbeauty.co.uk',
    'https://www.yslbeauty.com.au',
    'https://www.yslbeauty.com.hk',
    'https://www.yslbeauty.com.my',
    'https://www.yslbeauty.com.tw',
    'https://www.yslbeauty.fr',
    'https://www.yslbeautycn.com',
    'https://www.yslbeautykr.com',
    'https://www.yslbeautyth.com',
    'https://www.yslbeautyus.com',
    'https://www.yuesai.com',
    'https://yslbeauty.com.ru',
    'https://yslbeauty.com/de-DE/',
    'https://yslbeauty.com/en-EN/',
    'https://yslbeauty.com/en-SG/',
    'https://yslbeauty.com/es-ES/',
    'https://yslbeauty.com/es-MX/',
    'https://yslbeauty.com/it-IT/',
    'https://yslbeauty.com/pl-PL/',
    'https://za.mixa.com',
  ],
  master: {
    items: [
      [5, 70, 0, 0, 73],
      [1, 31, 4, 1, 74],
      [6, 17, 3, 2, 87],
      [6, 19, 3, 2, 88],
      [1, 23, 3, 2, 89],
      [3, 27, 3, 2, 86],
      [1, 33, 3, 2, 85],
      [5, 70, 4, 3, 90],
      [5, 6, 3, 4, 92],
      [1, 9, 3, 4, 96],
      [6, 13, 3, 4, 32],
      [6, 19, 3, 4, 101],
      [6, 20, 3, 4, 99],
      [1, 23, 3, 4, 7],
      [3, 27, 3, 4, 95],
      [6, 30, 3, 4, 102],
      [1, 33, 3, 4, 93],
      [1, 38, 3, 4, 97],
      [6, 45, 3, 4, 33],
      [2, 54, 3, 4, 34],
      [6, 61, 3, 4, 100],
      [6, 62, 3, 4, 35],
      [1, 64, 3, 4, 98],
      [1, 65, 3, 4, 94],
      [5, 70, 3, 4, 91],
      [6, 19, 4, 5, 107],
      [6, 20, 4, 5, 104],
      [6, 30, 4, 5, 108],
      [6, 42, 4, 5, 109],
      [6, 61, 4, 5, 106],
      [6, 69, 4, 5, 105],
      [5, 70, 2, 6, 110],
      [1, 1, 0, 7, 120],
      [6, 3, 0, 7, 112],
      [4, 4, 0, 7, 121],
      [2, 5, 0, 7, 113],
      [5, 6, 0, 7, 114],
      [4, 7, 0, 7, 111],
      [4, 8, 0, 7, 115],
      [1, 9, 0, 7, 116],
      [2, 12, 0, 7, 124],
      [6, 18, 0, 7, 127],
      [6, 19, 0, 7, 128],
      [6, 20, 0, 7, 125],
      [6, 22, 0, 7, 129],
      [1, 23, 0, 7, 130],
      [2, 24, 0, 7, 131],
      [6, 30, 0, 7, 132],
      [4, 39, 0, 7, 133],
      [6, 45, 0, 7, 134],
      [4, 49, 0, 7, 135],
      [2, 51, 0, 7, 136],
      [6, 52, 0, 7, 137],
      [2, 53, 0, 7, 138],
      [2, 54, 0, 7, 37],
      [2, 58, 0, 7, 140],
      [6, 61, 0, 7, 126],
      [6, 62, 0, 7, 139],
      [1, 64, 0, 7, 123],
      [1, 65, 0, 7, 117],
      [2, 66, 0, 7, 122],
      [6, 69, 0, 7, 118],
      [5, 70, 0, 7, 119],
      [4, 71, 0, 7, 141],
      [5, 6, 3, 8, 142],
      [1, 9, 3, 8, 143],
      [6, 69, 3, 8, 144],
      [5, 70, 3, 8, 145],
      [5, 70, 4, 9, 147],
      [4, 4, 2, 10, 146],
      [4, 0, 1, 11, 15],
      [1, 1, 1, 11, 431],
      [6, 2, 1, 11, 420],
      [4, 4, 1, 11, 432],
      [5, 6, 1, 11, 421],
      [4, 8, 1, 11, 423],
      [1, 9, 1, 11, 13],
      [1, 9, 1, 11, 20],
      [4, 10, 1, 11, 424],
      [2, 12, 1, 11, 436],
      [6, 19, 1, 11, 439],
      [6, 20, 1, 11, 437],
      [1, 23, 1, 11, 440],
      [2, 24, 1, 11, 441],
      [1, 25, 1, 11, 427],
      [1, 26, 1, 11, 425],
      [3, 27, 1, 11, 36],
      [3, 27, 1, 11, 180],
      [3, 27, 1, 11, 12],
      [3, 27, 1, 11, 14],
      [2, 29, 1, 11, 426],
      [6, 30, 1, 11, 442],
      [1, 31, 1, 11, 25],
      [1, 31, 1, 11, 24],
      [1, 33, 1, 11, 428],
      [1, 38, 1, 11, 444],
      [4, 39, 1, 11, 443],
      [0, 41, 1, 11, 18],
      [1, 43, 1, 11, 445],
      [4, 48, 1, 11, 433],
      [4, 49, 1, 11, 19],
      [1, 50, 1, 11, 16],
      [2, 51, 1, 11, 446],
      [2, 53, 1, 11, 447],
      [2, 54, 1, 11, 434],
      [1, 57, 1, 11, 448],
      [0, 60, 1, 11, 430],
      [6, 61, 1, 11, 438],
      [6, 63, 1, 11, 422],
      [1, 64, 1, 11, 435],
      [1, 65, 1, 11, 506],
      [2, 66, 1, 11, 17],
      [2, 67, 1, 11, 449],
      [6, 69, 1, 11, 429],
      [5, 70, 1, 11, 507],
      [4, 71, 1, 11, 22],
      [1, 73, 1, 11, 450],
      [4, 4, 0, 12, 167],
      [2, 51, 0, 12, 581],
      [6, 61, 0, 12, 691],
      [3, 27, 1, 14, 8],
      [4, 4, 4, 15, 50],
      [4, 4, 4, 15, 648],
      [5, 70, 4, 27, 226],
      [6, 19, 4, 16, 163],
      [6, 20, 4, 16, 161],
      [3, 27, 4, 16, 160],
      [6, 42, 4, 16, 164],
      [6, 61, 4, 16, 162],
      [6, 69, 4, 16, 159],
      [1, 1, 2, 17, 173],
      [5, 6, 2, 17, 169],
      [6, 19, 2, 17, 176],
      [6, 20, 2, 17, 174],
      [6, 22, 2, 17, 177],
      [1, 31, 2, 17, 178],
      [6, 61, 2, 17, 175],
      [6, 63, 2, 17, 170],
      [6, 69, 2, 17, 171],
      [5, 70, 2, 17, 172],
      [4, 0, 2, 18, 193],
      [1, 1, 2, 18, 194],
      [6, 3, 2, 18, 183],
      [4, 4, 2, 18, 195],
      [5, 6, 2, 18, 186],
      [4, 7, 2, 18, 184],
      [4, 8, 2, 18, 9],
      [4, 10, 2, 18, 196],
      [6, 13, 2, 18, 204],
      [6, 18, 2, 18, 206],
      [6, 19, 2, 18, 207],
      [6, 20, 2, 18, 203],
      [6, 22, 2, 18, 208],
      [1, 25, 2, 18, 209],
      [1, 26, 2, 18, 188],
      [2, 29, 2, 18, 189],
      [6, 30, 2, 18, 210],
      [1, 38, 2, 18, 198],
      [4, 39, 2, 18, 197],
      [0, 40, 2, 18, 185],
      [6, 42, 2, 18, 219],
      [6, 45, 2, 18, 211],
      [4, 49, 2, 18, 199],
      [1, 50, 2, 18, 200],
      [2, 51, 2, 18, 212],
      [6, 52, 2, 18, 213],
      [2, 53, 2, 18, 214],
      [2, 54, 2, 18, 215],
      [0, 60, 2, 18, 192],
      [6, 61, 2, 18, 205],
      [6, 62, 2, 18, 216],
      [6, 63, 2, 18, 187],
      [1, 65, 2, 18, 190],
      [2, 66, 2, 18, 201],
      [2, 67, 2, 18, 217],
      [6, 69, 2, 18, 191],
      [5, 70, 2, 18, 218],
      [4, 71, 2, 18, 202],
      [1, 1, 3, 19, 223],
      [5, 6, 3, 19, 75],
      [1, 9, 3, 19, 221],
      [6, 19, 3, 19, 81],
      [6, 20, 3, 19, 79],
      [1, 23, 3, 19, 224],
      [3, 27, 3, 19, 77],
      [6, 30, 3, 19, 82],
      [1, 31, 3, 19, 83],
      [1, 33, 3, 19, 222],
      [1, 38, 3, 19, 78],
      [2, 54, 3, 19, 31],
      [6, 61, 3, 19, 80],
      [1, 64, 3, 19, 225],
      [6, 69, 3, 19, 76],
      [5, 70, 3, 19, 220],
      [1, 9, 3, 20, 10],
      [6, 19, 3, 20, 229],
      [1, 23, 3, 20, 39],
      [3, 27, 3, 20, 230],
      [6, 30, 3, 20, 231],
      [1, 31, 3, 20, 233],
      [6, 42, 3, 20, 232],
      [2, 54, 3, 20, 40],
      [6, 61, 3, 20, 228],
      [1, 64, 3, 20, 227],
      [5, 6, 3, 21, 234],
      [6, 19, 3, 21, 238],
      [6, 20, 3, 21, 236],
      [6, 30, 3, 21, 239],
      [6, 61, 3, 21, 237],
      [6, 69, 3, 21, 235],
      [5, 70, 3, 21, 240],
      [5, 6, 3, 22, 242],
      [6, 19, 3, 22, 246],
      [6, 20, 3, 22, 245],
      [6, 69, 3, 22, 243],
      [5, 70, 3, 22, 244],
      [1, 1, 3, 23, 294],
      [6, 2, 3, 23, 285],
      [6, 3, 3, 23, 286],
      [5, 6, 3, 23, 287],
      [4, 8, 3, 23, 289],
      [1, 9, 3, 23, 295],
      [2, 12, 3, 23, 302],
      [6, 13, 3, 23, 304],
      [6, 19, 3, 23, 306],
      [6, 20, 3, 23, 303],
      [1, 23, 3, 23, 296],
      [1, 26, 3, 23, 42],
      [6, 30, 3, 23, 307],
      [1, 31, 3, 23, 308],
      [1, 33, 3, 23, 290],
      [1, 38, 3, 23, 315],
      [4, 39, 3, 23, 297],
      [6, 42, 3, 23, 309],
      [6, 45, 3, 23, 310],
      [1, 50, 3, 23, 298],
      [2, 51, 3, 23, 311],
      [6, 52, 3, 23, 44],
      [2, 53, 3, 23, 312],
      [2, 54, 3, 23, 45],
      [0, 55, 3, 23, 313],
      [1, 57, 3, 23, 299],
      [6, 61, 3, 23, 305],
      [6, 62, 3, 23, 314],
      [6, 63, 3, 23, 288],
      [1, 64, 3, 23, 301],
      [1, 65, 3, 23, 291],
      [2, 66, 3, 23, 300],
      [2, 67, 3, 23, 46],
      [0, 68, 3, 23, 284],
      [6, 69, 3, 23, 292],
      [5, 70, 3, 23, 293],
      [1, 73, 3, 23, 43],
      [4, 0, 4, 24, 259],
      [1, 1, 4, 24, 260],
      [6, 2, 4, 24, 249],
      [6, 3, 4, 24, 250],
      [4, 4, 4, 24, 261],
      [5, 6, 4, 24, 251],
      [4, 7, 4, 24, 247],
      [4, 8, 4, 24, 253],
      [4, 10, 4, 24, 262],
      [6, 13, 4, 24, 270],
      [6, 18, 4, 24, 272],
      [6, 19, 4, 24, 273],
      [6, 20, 4, 24, 269],
      [6, 22, 4, 24, 274],
      [1, 23, 4, 24, 263],
      [1, 25, 4, 24, 275],
      [1, 26, 4, 24, 254],
      [3, 27, 4, 24, 258],
      [2, 29, 4, 24, 255],
      [6, 30, 4, 24, 276],
      [1, 31, 4, 24, 277],
      [1, 33, 4, 24, 256],
      [1, 38, 4, 24, 265],
      [4, 39, 4, 24, 264],
      [6, 42, 4, 24, 278],
      [6, 45, 4, 24, 279],
      [0, 46, 4, 24, 60],
      [4, 49, 4, 24, 280],
      [2, 51, 4, 24, 266],
      [6, 52, 4, 24, 281],
      [2, 54, 4, 24, 41],
      [0, 55, 4, 24, 64],
      [6, 61, 4, 24, 271],
      [6, 62, 4, 24, 282],
      [6, 63, 4, 24, 252],
      [1, 64, 4, 24, 268],
      [2, 66, 4, 24, 267],
      [0, 68, 4, 24, 28],
      [6, 69, 4, 24, 257],
      [5, 70, 4, 24, 248],
      [4, 71, 4, 24, 283],
      [6, 19, 1, 25, 5],
      [6, 19, 1, 25, 713],
      [0, 40, 1, 25, 4],
      [0, 40, 2, 25, 84],
      [2, 54, 1, 25, 68],
      [2, 54, 2, 25, 1],
      [2, 66, 2, 25, 54],
      [2, 66, 2, 13, 65],
      [5, 70, 1, 25, 660],
      [5, 70, 2, 25, 509],
      [5, 70, 4, 25, 643],
      [4, 0, 2, 26, 456],
      [1, 1, 2, 26, 457],
      [6, 3, 2, 26, 397],
      [4, 4, 2, 26, 6],
      [4, 4, 2, 26, 402],
      [5, 6, 2, 26, 451],
      [4, 8, 2, 26, 453],
      [1, 9, 2, 26, 458],
      [4, 10, 2, 26, 459],
      [2, 12, 2, 26, 466],
      [6, 13, 2, 26, 467],
      [0, 15, 2, 26, 410],
      [2, 16, 2, 26, 409],
      [6, 18, 2, 26, 468],
      [6, 19, 2, 26, 412],
      [6, 20, 2, 26, 0],
      [6, 20, 2, 26, 408],
      [6, 20, 2, 26, 575],
      [6, 22, 2, 26, 469],
      [1, 23, 2, 26, 403],
      [2, 24, 2, 26, 470],
      [1, 25, 2, 26, 454],
      [1, 26, 2, 26, 398],
      [2, 29, 2, 26, 51],
      [6, 30, 2, 26, 413],
      [1, 31, 2, 26, 479],
      [1, 33, 2, 26, 455],
      [2, 35, 2, 26, 415],
      [0, 36, 2, 26, 404],
      [2, 37, 2, 26, 414],
      [1, 38, 2, 26, 460],
      [4, 39, 2, 26, 405],
      [0, 40, 2, 26, 396],
      [0, 41, 2, 26, 416],
      [6, 42, 2, 26, 417],
      [6, 45, 2, 26, 471],
      [0, 47, 2, 26, 406],
      [4, 48, 2, 26, 461],
      [4, 49, 2, 26, 472],
      [1, 50, 2, 26, 462],
      [2, 51, 2, 26, 473],
      [6, 52, 2, 26, 474],
      [2, 53, 2, 26, 475],
      [2, 54, 2, 26, 418],
      [1, 57, 2, 26, 407],
      [2, 58, 2, 26, 477],
      [0, 60, 2, 26, 29],
      [0, 60, 2, 26, 401],
      [6, 61, 2, 26, 411],
      [6, 62, 2, 26, 476],
      [6, 63, 2, 26, 452],
      [1, 64, 2, 26, 464],
      [1, 65, 2, 26, 399],
      [2, 66, 2, 26, 463],
      [2, 67, 2, 26, 52],
      [6, 69, 2, 26, 400],
      [5, 70, 2, 26, 480],
      [4, 71, 2, 26, 478],
      [1, 73, 2, 26, 465],
      [4, 0, 4, 27, 490],
      [1, 1, 4, 27, 491],
      [6, 3, 4, 27, 483],
      [4, 4, 4, 27, 241],
      [4, 4, 4, 27, 21],
      [5, 6, 4, 27, 484],
      [4, 7, 4, 27, 481],
      [4, 8, 4, 27, 486],
      [4, 10, 4, 27, 487],
      [6, 19, 4, 27, 181],
      [6, 19, 4, 27, 496],
      [6, 20, 4, 27, 494],
      [6, 22, 4, 27, 497],
      [1, 25, 4, 27, 499],
      [1, 26, 4, 27, 498],
      [3, 27, 4, 27, 493],
      [6, 30, 4, 27, 182],
      [6, 30, 4, 27, 500],
      [1, 31, 4, 27, 419],
      [1, 33, 4, 27, 488],
      [4, 39, 4, 27, 492],
      [0, 40, 4, 27, 482],
      [6, 42, 4, 27, 501],
      [4, 49, 4, 27, 502],
      [6, 52, 4, 27, 503],
      [2, 54, 4, 27, 53],
      [2, 54, 4, 27, 645],
      [1, 57, 4, 27, 504],
      [6, 61, 4, 27, 495],
      [6, 61, 4, 27, 644],
      [6, 63, 4, 27, 485],
      [6, 69, 4, 27, 489],
      [5, 70, 4, 27, 3],
      [4, 71, 4, 27, 505],
      [6, 19, 2, 28, 103],
      [6, 19, 2, 28, 23],
      [6, 19, 2, 28, 712],
      [6, 19, 2, 29, 49],
      [4, 0, 0, 30, 355],
      [1, 1, 0, 30, 356],
      [6, 2, 0, 30, 345],
      [6, 3, 0, 30, 346],
      [4, 4, 0, 30, 357],
      [2, 5, 0, 30, 347],
      [5, 6, 0, 30, 348],
      [4, 7, 0, 30, 386],
      [4, 8, 0, 30, 350],
      [1, 9, 0, 30, 11],
      [4, 10, 0, 30, 351],
      [2, 11, 0, 30, 358],
      [2, 12, 0, 30, 362],
      [6, 13, 0, 30, 363],
      [0, 15, 0, 30, 391],
      [6, 18, 0, 30, 365],
      [6, 19, 0, 30, 366],
      [6, 20, 0, 30, 390],
      [6, 22, 0, 30, 392],
      [1, 23, 0, 30, 367],
      [2, 24, 0, 30, 393],
      [3, 27, 0, 30, 354],
      [6, 28, 0, 30, 368],
      [2, 29, 0, 30, 352],
      [6, 30, 0, 30, 394],
      [1, 31, 0, 30, 369],
      [1, 33, 0, 30, 389],
      [0, 36, 0, 30, 387],
      [4, 39, 0, 30, 359],
      [0, 40, 0, 30, 371],
      [0, 41, 0, 30, 370],
      [6, 42, 0, 30, 372],
      [6, 45, 0, 30, 373],
      [4, 49, 0, 30, 374],
      [2, 51, 0, 30, 375],
      [6, 52, 0, 30, 376],
      [2, 53, 0, 30, 377],
      [2, 54, 0, 30, 379],
      [2, 56, 0, 30, 378],
      [1, 57, 0, 30, 381],
      [2, 58, 0, 30, 383],
      [2, 59, 0, 30, 382],
      [6, 61, 0, 30, 364],
      [6, 62, 0, 30, 380],
      [6, 63, 0, 30, 349],
      [1, 64, 0, 30, 508],
      [1, 65, 0, 30, 388],
      [2, 66, 0, 30, 360],
      [2, 67, 0, 30, 384],
      [6, 69, 0, 30, 353],
      [5, 70, 0, 30, 385],
      [4, 71, 0, 30, 361],
      [4, 72, 0, 30, 344],
      [1, 73, 0, 30, 395],
      [4, 0, 3, 31, 324],
      [1, 1, 3, 31, 325],
      [4, 4, 3, 31, 326],
      [5, 6, 3, 31, 318],
      [4, 8, 3, 31, 319],
      [1, 9, 3, 31, 327],
      [6, 13, 3, 31, 335],
      [6, 19, 3, 31, 337],
      [6, 20, 3, 31, 334],
      [1, 23, 3, 31, 328],
      [2, 29, 3, 31, 320],
      [6, 30, 3, 31, 338],
      [1, 31, 3, 31, 339],
      [1, 33, 3, 31, 321],
      [1, 38, 3, 31, 330],
      [4, 39, 3, 31, 329],
      [0, 40, 3, 31, 316],
      [6, 45, 3, 31, 340],
      [2, 51, 3, 31, 341],
      [2, 54, 3, 31, 47],
      [1, 57, 3, 31, 331],
      [6, 61, 3, 31, 336],
      [6, 62, 3, 31, 342],
      [1, 64, 3, 31, 333],
      [1, 65, 3, 31, 322],
      [2, 66, 3, 31, 332],
      [2, 67, 3, 31, 48],
      [6, 69, 3, 31, 323],
      [5, 70, 3, 31, 317],
      [1, 73, 3, 31, 343],
      [4, 0, 4, 32, 519],
      [1, 1, 4, 32, 520],
      [6, 3, 4, 32, 513],
      [5, 6, 4, 32, 514],
      [4, 8, 4, 32, 515],
      [6, 19, 4, 32, 524],
      [6, 20, 4, 32, 522],
      [1, 25, 4, 32, 525],
      [1, 26, 4, 32, 516],
      [6, 30, 4, 32, 526],
      [4, 39, 4, 32, 521],
      [1, 43, 4, 32, 517],
      [2, 54, 4, 32, 511],
      [6, 61, 4, 32, 523],
      [6, 69, 4, 32, 512],
      [5, 70, 4, 32, 510],
      [5, 70, 4, 32, 518],
      [4, 0, 2, 33, 543],
      [1, 1, 2, 33, 544],
      [6, 3, 2, 33, 529],
      [4, 4, 2, 33, 545],
      [4, 4, 2, 33, 26],
      [5, 6, 2, 33, 530],
      [4, 7, 2, 33, 527],
      [4, 8, 2, 33, 532],
      [1, 9, 2, 33, 574],
      [4, 10, 2, 33, 533],
      [6, 13, 2, 33, 557],
      [0, 15, 2, 33, 558],
      [6, 18, 2, 33, 560],
      [6, 19, 2, 33, 561],
      [6, 20, 2, 33, 556],
      [6, 22, 2, 33, 562],
      [1, 23, 2, 33, 546],
      [1, 25, 2, 33, 536],
      [1, 26, 2, 33, 534],
      [2, 29, 2, 33, 535],
      [6, 30, 2, 33, 563],
      [1, 31, 2, 33, 537],
      [1, 33, 2, 33, 538],
      [0, 36, 2, 33, 547],
      [1, 38, 2, 33, 549],
      [4, 39, 2, 33, 548],
      [0, 40, 2, 33, 528],
      [0, 41, 2, 33, 564],
      [6, 42, 2, 33, 565],
      [6, 45, 2, 33, 566],
      [0, 47, 2, 33, 551],
      [4, 49, 2, 33, 567],
      [1, 50, 2, 33, 550],
      [2, 51, 2, 33, 568],
      [6, 52, 2, 33, 569],
      [2, 53, 2, 33, 570],
      [2, 54, 2, 33, 552],
      [1, 57, 2, 33, 553],
      [0, 60, 2, 33, 30],
      [0, 60, 2, 33, 541],
      [6, 61, 2, 33, 559],
      [6, 62, 2, 33, 571],
      [6, 63, 2, 33, 531],
      [1, 64, 2, 33, 555],
      [1, 65, 2, 33, 539],
      [2, 66, 2, 33, 554],
      [2, 67, 2, 33, 55],
      [6, 69, 2, 33, 540],
      [5, 70, 2, 33, 168],
      [5, 70, 2, 33, 542],
      [4, 71, 2, 33, 572],
      [1, 73, 2, 33, 573],
      [2, 12, 2, 34, 579],
      [6, 19, 2, 34, 576],
      [6, 20, 2, 34, 38],
      [2, 24, 2, 34, 577],
      [0, 41, 2, 34, 2],
      [2, 58, 2, 34, 578],
      [0, 60, 2, 34, 781],
      [2, 67, 2, 34, 56],
      [5, 70, 4, 35, 580],
      [1, 1, 2, 36, 590],
      [6, 3, 2, 36, 584],
      [5, 6, 2, 36, 585],
      [4, 7, 2, 36, 582],
      [4, 8, 2, 36, 587],
      [6, 13, 2, 36, 583],
      [4, 14, 2, 36, 595],
      [2, 16, 2, 36, 59],
      [6, 19, 2, 36, 597],
      [6, 20, 2, 36, 594],
      [6, 22, 2, 36, 598],
      [1, 23, 2, 36, 599],
      [6, 30, 2, 36, 600],
      [1, 31, 2, 36, 601],
      [4, 39, 2, 36, 591],
      [4, 49, 2, 36, 602],
      [2, 51, 2, 36, 603],
      [2, 53, 2, 36, 58],
      [2, 54, 2, 36, 57],
      [6, 61, 2, 36, 596],
      [6, 63, 2, 36, 586],
      [1, 64, 2, 36, 593],
      [2, 66, 2, 36, 592],
      [6, 69, 2, 36, 588],
      [5, 70, 2, 36, 589],
      [4, 4, 2, 37, 179],
      [5, 70, 4, 38, 604],
      [1, 1, 4, 39, 62],
      [5, 6, 4, 39, 605],
      [1, 43, 4, 39, 608],
      [6, 69, 4, 39, 606],
      [5, 70, 4, 39, 607],
      [1, 1, 4, 40, 616],
      [6, 3, 4, 40, 610],
      [4, 4, 4, 40, 617],
      [5, 6, 4, 40, 611],
      [4, 7, 4, 40, 609],
      [4, 8, 4, 40, 613],
      [2, 12, 4, 40, 620],
      [6, 13, 4, 40, 622],
      [6, 18, 4, 40, 623],
      [6, 19, 4, 40, 624],
      [6, 20, 4, 40, 621],
      [2, 24, 4, 40, 625],
      [6, 30, 4, 40, 626],
      [4, 39, 4, 40, 619],
      [6, 45, 4, 40, 627],
      [4, 49, 4, 40, 628],
      [2, 51, 4, 40, 632],
      [6, 52, 4, 40, 629],
      [2, 54, 4, 40, 63],
      [6, 61, 4, 40, 618],
      [6, 62, 4, 40, 630],
      [6, 63, 4, 40, 612],
      [6, 69, 4, 40, 614],
      [5, 70, 4, 40, 615],
      [4, 71, 4, 40, 631],
      [6, 3, 0, 41, 639],
      [6, 19, 0, 41, 637],
      [6, 20, 0, 41, 634],
      [6, 22, 0, 41, 635],
      [6, 28, 0, 41, 641],
      [6, 30, 0, 41, 638],
      [1, 31, 0, 41, 642],
      [6, 52, 0, 41, 640],
      [6, 61, 0, 41, 636],
      [6, 69, 0, 41, 633],
      [1, 9, 2, 42, 689],
      [6, 19, 2, 42, 66],
      [6, 19, 2, 42, 690],
      [6, 19, 0, 43, 646],
      [5, 70, 4, 44, 647],
      [5, 6, 3, 45, 650],
      [1, 9, 3, 45, 651],
      [1, 23, 3, 45, 652],
      [1, 31, 3, 45, 657],
      [1, 38, 3, 45, 653],
      [2, 54, 3, 45, 67],
      [1, 57, 3, 45, 654],
      [1, 64, 3, 45, 655],
      [1, 65, 3, 45, 659],
      [5, 70, 3, 45, 649],
      [1, 73, 3, 45, 656],
      [5, 70, 4, 46, 658],
      [1, 1, 0, 47, 670],
      [6, 3, 0, 47, 665],
      [4, 4, 0, 47, 671],
      [5, 6, 0, 47, 666],
      [1, 9, 0, 47, 672],
      [6, 13, 0, 47, 677],
      [6, 19, 0, 47, 679],
      [6, 20, 0, 47, 676],
      [6, 22, 0, 47, 680],
      [1, 23, 0, 47, 673],
      [6, 30, 0, 47, 681],
      [1, 33, 0, 47, 661],
      [4, 34, 0, 47, 662],
      [0, 36, 0, 47, 663],
      [4, 39, 0, 47, 674],
      [6, 42, 0, 47, 682],
      [6, 45, 0, 47, 683],
      [6, 52, 0, 47, 684],
      [2, 54, 0, 47, 685],
      [1, 57, 0, 47, 687],
      [0, 60, 0, 47, 664],
      [6, 61, 0, 47, 678],
      [6, 62, 0, 47, 686],
      [6, 63, 0, 47, 667],
      [2, 66, 0, 47, 675],
      [6, 69, 0, 47, 668],
      [5, 70, 0, 47, 669],
      [0, 21, 2, 48, 157],
      [0, 32, 2, 48, 155],
      [0, 44, 2, 48, 158],
      [0, 60, 2, 48, 156],
      [5, 70, 2, 48, 688],
      [6, 3, 3, 49, 694],
      [5, 6, 3, 49, 695],
      [4, 8, 3, 49, 696],
      [2, 12, 3, 49, 704],
      [6, 13, 3, 49, 692],
      [6, 19, 3, 49, 707],
      [6, 20, 3, 49, 705],
      [1, 23, 3, 49, 702],
      [6, 30, 3, 49, 708],
      [1, 33, 3, 49, 697],
      [1, 38, 3, 49, 703],
      [4, 39, 3, 49, 709],
      [6, 42, 3, 49, 693],
      [6, 45, 3, 49, 710],
      [2, 54, 3, 49, 69],
      [0, 60, 3, 49, 700],
      [6, 61, 3, 49, 706],
      [6, 62, 3, 49, 711],
      [1, 65, 3, 49, 698],
      [6, 69, 3, 49, 699],
      [5, 70, 3, 49, 701],
      [4, 0, 0, 50, 756],
      [6, 2, 0, 50, 716],
      [6, 3, 0, 50, 717],
      [4, 4, 0, 50, 725],
      [2, 5, 0, 50, 718],
      [5, 6, 0, 50, 165],
      [5, 6, 0, 50, 719],
      [4, 7, 0, 50, 757],
      [4, 8, 0, 50, 721],
      [1, 9, 0, 50, 27],
      [4, 10, 0, 50, 726],
      [2, 11, 0, 50, 740],
      [2, 12, 0, 50, 731],
      [6, 13, 0, 50, 733],
      [0, 15, 0, 50, 734],
      [6, 18, 0, 50, 736],
      [6, 19, 0, 50, 737],
      [6, 20, 0, 50, 732],
      [6, 22, 0, 50, 738],
      [1, 23, 0, 50, 739],
      [2, 24, 0, 50, 741],
      [6, 28, 0, 50, 742],
      [2, 29, 0, 50, 722],
      [6, 30, 0, 50, 743],
      [0, 36, 0, 50, 714],
      [4, 39, 0, 50, 727],
      [0, 40, 0, 50, 715],
      [0, 41, 0, 50, 744],
      [6, 42, 0, 50, 745],
      [6, 45, 0, 50, 746],
      [2, 51, 0, 50, 747],
      [6, 52, 0, 50, 748],
      [2, 53, 0, 50, 749],
      [2, 54, 0, 50, 758],
      [2, 56, 0, 50, 750],
      [1, 57, 0, 50, 752],
      [2, 58, 0, 50, 754],
      [2, 59, 0, 50, 753],
      [0, 60, 0, 50, 724],
      [6, 61, 0, 50, 735],
      [6, 62, 0, 50, 751],
      [6, 63, 0, 50, 720],
      [1, 64, 0, 50, 729],
      [1, 65, 0, 50, 71],
      [2, 66, 0, 50, 728],
      [2, 67, 0, 50, 755],
      [6, 69, 0, 50, 723],
      [5, 70, 0, 50, 166],
      [5, 70, 0, 50, 759],
      [4, 71, 0, 50, 730],
      [1, 73, 0, 50, 72],
      [6, 19, 3, 51, 61],
      [5, 70, 3, 51, 70],
      [4, 0, 2, 52, 150],
      [4, 7, 2, 52, 148],
      [4, 8, 2, 52, 149],
      [4, 10, 2, 52, 151],
      [4, 39, 2, 52, 152],
      [4, 49, 2, 52, 153],
      [4, 71, 2, 52, 154],
      [1, 9, 3, 53, 772],
      [1, 1, 3, 54, 763],
      [5, 6, 3, 54, 761],
      [1, 9, 3, 54, 768],
      [6, 19, 3, 54, 767],
      [6, 20, 3, 54, 774],
      [1, 23, 3, 54, 764],
      [3, 27, 3, 54, 775],
      [6, 30, 3, 54, 779],
      [1, 31, 3, 54, 760],
      [1, 33, 3, 54, 769],
      [1, 38, 3, 54, 765],
      [4, 39, 3, 54, 778],
      [2, 51, 3, 54, 780],
      [2, 54, 3, 54, 773],
      [1, 57, 3, 54, 776],
      [6, 61, 3, 54, 777],
      [1, 64, 3, 54, 766],
      [1, 65, 3, 54, 770],
      [6, 69, 3, 54, 762],
      [5, 70, 3, 54, 771],
    ],
    matching: ['zone', 'country', 'division', 'brand', 'url'],
  },
}
