export async function registerAllTemplates() {
  const [
    { registerToolOverviewTemplates },
    { registerToolStructureTemplates },
    { registerToolDataPrivacyTemplates },
    { registerTopicOverviewTemplates },
    { registerToolUncategorizedTemplates },
  ] = await Promise.all([
    import('@/components/templates/tool-overview'),
    import('@/components/templates/tool-structure'),
    import('@/components/templates/tool-dataprivacy'),
    import('@/components/templates/topic-overview'),
    import('@/components/templates/tool-uncategorized'),
  ])

  registerToolOverviewTemplates()
  registerToolStructureTemplates()
  registerToolDataPrivacyTemplates()
  registerTopicOverviewTemplates()
  registerToolUncategorizedTemplates()
}
