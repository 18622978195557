export const topics = {
  topics: {
    items: [
      [1588174978362, 81.51, 3, 76.23, 86.67, 100, 57.86],
      [1588174930942, 59.7, 3, 48.18, 76.67, 100, 2.42],
      [1588174907674, 70.76, 3, 67.1, 46.67, 100, 65.6],
      [1588174907446, 99.47, 3, 99.55, 100, 100, 98.41],
      [1588174908363, 73.93, 3, 66.48, 86.67, 100, 35.11],
      [1588174908179, 69.83, 3, 66.41, 46.67, 97.22, 65.6],
      [1588174907245, 51.58, 3, 37.75, 53.33, 100, 1.42],
      [1588174930797, 44.85, 3, 43.38, 73.33, 50, 11.22],
      [1588174936402, 51.22, 3, 51.56, 93.33, 50, 10.32],
      [1588174936747, 77.83, 3, 71.49, 86.67, 100, 46.81],
      [1588174937724, 76.23, 3, 73.64, 70, 96.88, 61.8],
      [1588174936655, 99.88, 3, 99.87, 100, 100, 99.65],
      [1588174937282, 99.76, 3, 99.73, 100, 100, 99.29],
      [1588174937383, 57.14, 3, 59.18, 86.67, 50, 34.75],
      [1588174936909, 52.02, 3, 49.15, 46.67, 75, 34.4],
      [1588174937198, 99.43, 3, 99.36, 100, 100, 98.29],
      [1588174937690, 34.92, 3, 30.61, 53.33, 50, 1.42],
      [1588174937505, 46.03, 3, 44.9, 86.67, 50, 1.42],
      [1588174937942, 77.84, 3, 75.07, 70, 100, 63.51],
      [1588174937966, 88.53, 3, 85.26, 100, 100, 65.6],
      [1588174936884, 78.37, 3, 76.08, 86.67, 96.66, 51.77],
      [1588174938092, 76.86, 3, 74.33, 70, 97.06, 63.51],
      [1588174936466, 46.03, 3, 44.9, 86.67, 50, 1.42],
      [1588174938138, 48.25, 3, 47.75, 93.33, 50, 1.42],
      [1588174937138, 43.44, 3, 41.57, 70, 50, 10.32],
      [1588174911061, 100, 3, 100, 100, 100, 100],
      [1588174910705, 97.45, 3, 97.14, 93.33, 100, 99.03],
      [1588174910318, 97.05, 3, 96.68, 93.33, 100, 97.81],
      [1588174910657, 67.64, 3, 68.69, 86.67, 59.26, 57],
      [1588174910550, 79.16, 3, 76.56, 80, 100, 57.49],
      [1588174911325, 84.82, 3, 86.77, 86.67, 69.23, 98.55],
      [1588174942807, 60.79, 3, 49.59, 73.33, 100, 9.05],
      [1588174975977, 79.02, 3, 73.02, 93.33, 100, 43.72],
      [1588174974581, 99.84, 3, 99.82, 100, 100, 99.52],
      [1588174974664, 85.99, 3, 81.99, 100, 100, 57.97],
      [1588174977158, 78.37, 3, 75.67, 76.67, 100, 58.45],
      [1588174976637, 80.76, 3, 75.26, 83.33, 100, 58.94],
      [1588174976413, 65.83, 3, 56.07, 40, 100, 57.49],
      [1588174975313, 74.88, 3, 67.7, 66.67, 100, 57.97],
      [1588174975969, 62.87, 3, 52.26, 86.67, 100, 1.93],
      [1588174976719, 80.43, 3, 77.99, 83.33, 100, 57.97],
      [1588174975676, 73.03, 3, 73.5, 83.33, 69.23, 66.52],
      [1588174974149, 99.84, 3, 99.82, 100, 100, 99.52],
      [1588174974390, 99.68, 3, 99.64, 100, 100, 99.03],
      [1588174974388, 100, 3, 100, 100, 100, 100],
      [1588174975434, 83.77, 3, 79.13, 93.33, 100, 57.97],
      [1588174976262, 78.21, 3, 75.49, 76.67, 100, 57.97],
      [1588174977273, 93.87, 3, 93.11, 83.33, 100, 98.29],
      [1588174975041, 78.01, 3, 71.73, 83.33, 100, 50.71],
      [1588174975587, 73.06, 3, 73.54, 76.67, 69.23, 73.28],
      [1588174976254, 78.24, 3, 72.03, 70, 100, 64.73],
      [1588174973944, 100, 3, 100, 100, 100, 100],
      [1588174977043, 100, 3, 100, 100, 100, 100],
      [1588174975178, 94.88, 3, 94.24, 93.33, 100, 91.3],
      [1588174973837, 88.57, 3, 85.3, 100, 100, 65.7],
      [1588174976958, 79.2, 3, 76.6, 83.33, 100, 54.27],
      [1588174974231, 77.01, 3, 74.53, 76.67, 96.88, 57.49],
      [1588174975821, 73.06, 3, 73.54, 76.67, 69.23, 73.28],
      [1588174974787, 66.94, 3, 57.5, 76.67, 100, 24.16],
      [1588174976460, 51.75, 3, 37.97, 53.33, 100, 1.93],
      [1588174975412, 64.52, 3, 63.21, 70, 75, 48.55],
      [1588174976830, 99.84, 3, 99.82, 100, 100, 99.52],
      [1588174973852, 85.51, 3, 81.37, 100, 100, 56.52],
      [1588174975174, 87.84, 3, 84.37, 83.33, 100, 80.19],
      [1588174930693, 48.88, 3, 48.56, 86.67, 50, 9.97],
      [1588174930622, 43.81, 3, 42.04, 80, 50, 1.42],
      [1588174930536, 89.39, 3, 91.91, 100, 69.23, 98.94],
      [1588174930317, 69.2, 3, 60.4, 86.67, 100, 20.92],
      [1588174978501, 71.06, 3, 62.8, 63.33, 100, 49.86],
      [1588174898364, 52.22, 3, 38.57, 56.67, 100, 0],
      [1588174957752, 76.62, 3, 72.17, 73.33, 100, 56.52],
      [1588174958761, 78.84, 3, 75.51, 80, 100, 56.52],
      [1588174960073, 78.84, 3, 79.01, 80, 100, 56.52],
      [1588174961257, 64.78, 3, 54.72, 56.67, 100, 37.68],
      [1588174961266, 80.82, 3, 78.6, 86.67, 100, 55.8],
      [1588174961565, 59.23, 3, 47.58, 40, 100, 37.68],
      [1588174891286, 76.13, 3, 71.69, 73.33, 100, 55.07],
      [1588174962564, 76.62, 3, 72.17, 73.33, 100, 56.52],
      [1588174958885, 76.62, 3, 72.17, 73.33, 100, 56.52],
      [1588174961442, 68.92, 3, 69.55, 73.33, 76.92, 56.52],
      [1588174961747, 99.52, 3, 99.59, 100, 100, 98.55],
      [1588174961439, 92.61, 3, 90.81, 80, 100, 97.83],
      [1588174962376, 78.84, 3, 75.51, 80, 100, 56.52],
      [1588174962193, 68.92, 3, 69.55, 73.33, 76.92, 56.52],
      [1588174960260, 76.62, 3, 72.17, 73.33, 100, 56.52],
      [1588174958123, 76.62, 3, 72.17, 73.33, 100, 56.52],
      [1588174942852, 33.58, 3, 34.66, 40, 25, 35.75],
      [1588174961437, 57.15, 3, 55.64, 46.67, 69.23, 55.56],
      [1588174891216, 50.73, 3, 47.7, 40, 75, 37.2],
      [1588174961437, 57.31, 3, 55.82, 46.67, 69.23, 56.04],
      [1588174959731, 76.62, 3, 72.17, 73.33, 100, 56.52],
      [1588174962377, 100, 3, 100, 100, 100, 100],
      [1588174959339, 76.38, 3, 71.93, 73.33, 100, 55.8],
      [1588174959339, 76.38, 3, 71.93, 73.33, 100, 55.8],
      [1588174959445, 71.06, 3, 63.84, 56.67, 100, 56.52],
      [1588174958525, 76.62, 3, 72.17, 73.33, 100, 56.52],
      [1588174962192, 76.62, 3, 72.17, 73.33, 100, 56.52],
      [1588174958389, 80.82, 3, 78.6, 86.67, 100, 55.8],
      [1588174960485, 76.62, 3, 72.17, 73.33, 100, 56.52],
      [1588174957922, 59.95, 3, 63.84, 73.33, 50, 56.52],
      [1588174957717, 76.62, 3, 72.17, 73.33, 100, 56.52],
      [1588174959580, 78.84, 3, 75.51, 80, 100, 56.52],
      [1588174960173, 100, 3, 100, 100, 100, 100],
      [1588174962744, 85.89, 3, 84.13, 86.67, 100, 71.01],
      [1588174961836, 97.05, 3, 95.94, 93.33, 100, 97.83],
      [1588174961040, 94.83, 3, 92.61, 86.67, 100, 97.83],
      [1588174959869, 76.38, 3, 71.93, 73.33, 100, 55.8],
      [1588174961748, 90.14, 3, 87.74, 73.33, 100, 97.1],
      [1588174960591, 79.08, 3, 79.21, 80, 100, 57.24],
      [1588174959259, 76.62, 3, 72.17, 73.33, 100, 56.52],
      [1588174959026, 76.62, 3, 72.17, 73.33, 100, 56.52],
      [1588174959836, 66.36, 3, 67.36, 73.33, 69.23, 56.52],
      [1588174958302, 66.36, 3, 67.36, 73.33, 69.23, 56.52],
      [1588174958654, 92.61, 3, 90.81, 80, 100, 97.83],
      [1588174962752, 78.84, 3, 75.51, 80, 100, 56.52],
      [1588174958202, 76.62, 3, 72.17, 73.33, 100, 56.52],
      [1588174959046, 92.37, 3, 89.03, 80, 100, 97.1],
      [1588174935626, 47.95, 3, 33.08, 40, 100, 3.86],
      [1588174934742, 99.17, 3, 99.38, 100, 97.5, 100],
      [1588174822547, 58.33, 3, 50, 50, 100, 25],
      [null, 0, 2, 0, 0, null, 0],
      [1588174972564, 67.1, 3, 57.7, 76.67, 100, 24.64],
      [1588174972564, 67.1, 3, 57.7, 76.67, 100, 24.64],
      [1588174972600, 76.34, 3, 69.58, 76.67, 100, 52.34],
      [1588174925187, 99.84, 3, 99.82, 100, 100, 99.52],
      [1588174924904, 97.29, 3, 96.95, 93.33, 100, 98.55],
      [1588174925254, 57.17, 3, 54.95, 40, 75, 56.52],
      [1588174924720, 79, 3, 76.38, 80, 100, 57],
      [1588174925118, 78.08, 3, 75.34, 70, 100, 64.25],
      [1588174925203, 90.21, 3, 91.76, 93.33, 77.78, 99.52],
      [1588174929538, 54.93, 3, 42.05, 63.33, 100, 1.45],
      [1588174930101, 48.89, 3, 48.57, 86.67, 50, 10],
      [1588174929177, 99.84, 3, 99.82, 100, 100, 99.52],
      [1588174929373, 97.29, 3, 96.95, 93.33, 100, 98.55],
      [1588174930211, 100, 3, 100, 100, 100, 100],
      [1588174929858, 59.7, 3, 48.18, 76.67, 100, 2.42],
      [1588174930191, 83.64, 3, 81.59, 86.67, 100, 64.25],
      [1588174929670, 95.07, 3, 94.46, 86.67, 100, 98.55],
      [1588174929664, 92.85, 3, 91.96, 80, 100, 98.55],
      [1588174929760, 64.56, 3, 54.43, 70, 100, 23.67],
      [1588174914706, 96.57, 3, 95.59, 100, 100, 89.72],
      [1588174912216, 74.5, 3, 67.22, 100, 100, 23.51],
      [1588174911516, 99.52, 3, 99.46, 100, 100, 98.55],
      [1588174912914, 63.69, 3, 53.31, 83.33, 100, 7.73],
      [1588174912468, 79.5, 3, 73.64, 100, 100, 38.5],
      [1588174914874, 56.65, 3, 44.26, 40, 100, 29.95],
      [1588174913838, 66.18, 3, 56.52, 66.67, 100, 31.88],
      [1588174914581, 68.87, 3, 59.98, 76.67, 100, 29.95],
      [1588174912827, 83.32, 3, 81.23, 76.67, 100, 73.28],
      [1588174912929, 82.87, 3, 80.73, 76.67, 100, 71.94],
      [1588174912057, 99.84, 3, 99.82, 100, 100, 99.52],
      [1588174911770, 99.68, 3, 99.64, 100, 100, 99.03],
      [1588174912478, 100, 3, 100, 100, 100, 100],
      [1588174913649, 64.96, 3, 54.95, 86.67, 100, 8.21],
      [1588174913720, 64.01, 3, 53.73, 83.33, 100, 8.7],
      [1588174914270, 68.87, 3, 59.98, 76.67, 100, 29.95],
      [1588174911293, 99.43, 3, 99.36, 100, 100, 98.29],
      [1588174913751, 69.57, 3, 60.87, 100, 100, 8.7],
      [1588174914663, 77.92, 3, 71.61, 93.33, 100, 40.43],
      [1588174914643, 63.6, 3, 53.2, 40, 100, 50.79],
      [1588174911415, 95.07, 3, 94.46, 86.67, 100, 98.55],
      [1588174913511, 80.77, 3, 78.37, 70, 100, 72.31],
      [1588174914611, 68.87, 3, 59.98, 76.67, 100, 29.95],
      [1588174913942, 56.07, 3, 43.52, 60, 100, 8.21],
      [1588174912100, 100, 3, 100, 100, 100, 100],
      [1588174912771, 100, 3, 100, 100, 100, 100],
      [1588174914159, 73.75, 3, 74.32, 93.33, 69.23, 58.7],
      [1588174912389, 99.36, 3, 99.17, 100, 100, 98.07],
      [1588174915993, 53.69, 3, 54.74, 70, 50, 41.06],
      [1588174911993, 82.41, 3, 80.21, 86.67, 100, 60.55],
      [1588174913381, 79.42, 3, 77.29, 70, 96.43, 71.83],
      [1588174914452, 99.52, 3, 99.46, 100, 100, 98.55],
      [1588174913618, 69.24, 3, 60.46, 100, 100, 7.73],
      [1588174911077, 70.36, 3, 66.65, 86.67, 100, 24.4],
      [1588174915314, 69.73, 3, 69.79, 76.67, 69.23, 63.29],
      [1588174912952, 97.45, 3, 97.14, 93.33, 100, 99.03],
      [1588174914568, 86.53, 3, 82.68, 100, 100, 59.58],
      [1588174915156, 83.32, 3, 78.55, 93.33, 100, 56.62],
      [1588174892150, 50.14, 3, 50.18, 76.67, 50, 23.76],
      [1588174892886, 48.88, 3, 48.56, 86.67, 50, 9.97],
      [1588174892411, 45.91, 3, 44.74, 86.67, 50, 1.06],
      [1588174891576, 99.65, 3, 99.6, 100, 100, 98.94],
      [1588174892160, 99.65, 3, 99.6, 100, 100, 98.94],
      [1588174893141, 73.69, 3, 66.17, 86.67, 100, 34.4],
      [1588174891702, 55.04, 3, 49.42, 46.67, 100, 18.44],
      [1588174892020, 83.33, 3, 81.25, 83.33, 100, 66.67],
      [1588174892309, 72.93, 3, 65.2, 83.33, 100, 35.46],
      [1588174892577, 34.8, 3, 30.45, 53.33, 50, 1.06],
      [1588174893211, 50.47, 3, 36.32, 50, 100, 1.42],
      [1588174893108, 88.53, 3, 85.26, 100, 100, 65.6],
      [1588174891907, 80.52, 3, 78.09, 76.67, 100, 64.89],
      [1588174892735, 40.35, 3, 37.6, 70, 50, 1.06],
      [1588174891714, 89.8, 3, 91.39, 93.33, 77.14, 98.94],
      [1588174892677, 63.8, 3, 53.45, 86.67, 100, 4.72],
      [1588174906909, 54.8, 3, 41.88, 63.33, 100, 1.06],
      [1588174907121, 84.09, 3, 82.1, 70, 100, 82.27],
      [1588174907558, 84.07, 3, 79.51, 93.33, 100, 58.87],
      [1588174907000, 60.53, 3, 58.72, 46.67, 75, 59.93],
      [1588174907334, 77.78, 3, 75, 66.67, 100, 66.67],
      [1588174906831, 64.23, 3, 54.01, 70, 100, 22.7],
      [1588174907435, 50.22, 3, 47.84, 63.33, 69.23, 18.09],
      [1588174907000, 40.97, 3, 38.39, 46.67, 50, 26.24],
      [1588174907387, 57.26, 3, 51.91, 53.33, 100, 18.44],
      [1588174907652, 34.92, 3, 30.61, 53.33, 50, 1.42],
      [1588174978260, 43.44, 3, 41.57, 70, 50, 10.32],
      [1588174978095, 100, 3, 100, 100, 100, 100],
      [1588174977949, 99.65, 3, 99.6, 100, 100, 98.94],
      [1588174978151, 93.87, 3, 93.11, 83.33, 100, 98.29],
      [1588174977929, 70.63, 3, 67.15, 63.33, 98.48, 50.07],
      [1588174977591, 87.74, 3, 89.88, 93.33, 70.59, 99.29],
      [1588174977736, 72.53, 3, 64.68, 63.33, 100, 54.26],
      [1588174972914, 43.32, 3, 41.42, 70, 50, 9.97],
      [1588174973135, 100, 3, 100, 100, 100, 100],
      [1588174973249, 92.98, 3, 92.1, 80, 100, 98.94],
      [1588174973353, 88.4, 3, 90.42, 93.33, 72.22, 99.65],
      [1588174973009, 66.37, 3, 56.77, 93.33, 100, 5.79],
      [1588174939615, 68.91, 3, 60.03, 83.33, 100, 23.4],
      [1588174939801, 76.35, 3, 76.97, 83.33, 71.43, 74.3],
      [1588174939824, 97.42, 3, 97.1, 93.33, 100, 98.94],
      [1588174938861, 49, 3, 48.71, 86.67, 50, 10.32],
      [1588174940497, 76.19, 3, 69.39, 66.67, 100, 61.9],
      [1588174939257, 45.91, 3, 44.74, 86.67, 50, 1.06],
      [1588174941761, 65.36, 3, 64.15, 86.67, 75, 34.4],
      [1588174940389, 79.84, 3, 77.54, 76.67, 98.28, 64.58],
      [1588174939437, 99.3, 3, 99.48, 100, 97.91, 100],
      [1588174939088, 99.88, 3, 99.87, 100, 100, 99.65],
      [1588174942127, 54.8, 3, 56.17, 80, 50, 34.4],
      [1588174941858, 42.81, 3, 40.76, 76.67, 50, 1.77],
      [1588174939191, 99.43, 3, 99.36, 100, 100, 98.29],
      [1588174938567, 82.27, 3, 77.2, 83.33, 100, 63.48],
      [1588174941919, 37.14, 3, 33.47, 60, 50, 1.42],
      [1588174940632, 40.47, 3, 37.75, 70, 50, 1.42],
      [1588174940908, 78.88, 3, 72.85, 76.67, 100, 59.97],
      [1588174941093, 97.66, 3, 97.37, 93.33, 100, 99.65],
      [1588174940249, 80.3, 3, 77.83, 76.67, 100, 64.22],
      [1588174818577, 19.24, 3, 24.74, 56.67, 0, 1.06],
      [1588174941643, 100, 3, 100, 100, 100, 100],
      [1588174942380, 100, 3, 100, 100, 100, 100],
      [1588174941308, 90.67, 3, 90.61, 86.67, 91.17, 94.17],
      [1588174940911, 88.53, 3, 85.26, 100, 100, 65.6],
      [1588174941507, 72.39, 3, 64.5, 53.33, 100, 63.83],
      [1588174938682, 95.32, 3, 93.98, 86.67, 100, 99.29],
      [1588174938877, 79.67, 3, 77.54, 86.67, 96.66, 55.67],
      [1588174940236, 79.72, 3, 77.4, 76.67, 98.28, 64.22],
      [1588174939702, 99.65, 3, 99.6, 100, 100, 98.94],
      [1588174938373, 40.59, 3, 37.9, 70, 50, 1.77],
      [1588174940803, 40.47, 3, 37.75, 70, 50, 1.42],
      [1588174940147, 50.75, 3, 47.61, 70, 75.86, 6.38],
      [1588174941556, 63.85, 3, 65.59, 76.67, 50, 64.89],
      [1588174941169, 63, 3, 52.43, 66.67, 100, 22.34],
      [1588174938388, 89.66, 3, 91.37, 93.33, 76, 99.65],
      [1588174940635, 64.08, 3, 53.82, 86.67, 100, 5.58],
      [1588174942810, 80.76, 3, 75.26, 76.67, 100, 65.6],
      [1588174893379, 90.85, 3, 88.24, 83.33, 100, 89.22],
      [1588174894405, 73.64, 3, 66.11, 76.67, 100, 44.25],
      [1588174896170, 72.91, 3, 73.37, 83.33, 69.23, 66.18],
      [1588174894166, 99.68, 3, 99.64, 100, 100, 99.03],
      [1588174893614, 78.37, 3, 72.19, 76.67, 100, 58.45],
      [1588174896706, 69.79, 3, 61.16, 76.67, 100, 32.7],
      [1588174897762, 54.88, 3, 41.99, 40, 100, 24.64],
      [1588174896971, 72.66, 3, 64.84, 60, 100, 57.97],
      [1588174897732, 85.62, 3, 81.51, 76.67, 100, 80.19],
      [1588174895742, 82.08, 3, 79.84, 76.67, 100, 69.58],
      [1588174894884, 81.8, 3, 79.52, 76.67, 100, 68.72],
      [1588174897064, 100, 3, 100, 100, 100, 100],
      [1588174894456, 99.68, 3, 99.64, 100, 100, 99.03],
      [1588174895306, 100, 3, 100, 100, 100, 100],
      [1588174896288, 86.47, 3, 82.61, 100, 100, 59.42],
      [1588174895454, 64.09, 3, 68.12, 83.33, 50, 58.94],
      [1588174895568, 80.59, 3, 75.05, 83.33, 100, 58.45],
      [1588174896475, 74.88, 3, 71.74, 66.67, 100, 57.97],
      [1588174898065, 67.1, 3, 57.7, 76.67, 100, 24.64],
      [1588174896546, 93.59, 3, 92.79, 83.33, 100, 97.44],
      [1588174896740, 80.63, 3, 75.1, 76.67, 100, 65.22],
      [1588174895380, 58.7, 3, 61.18, 66.67, 50, 59.42],
      [1588174894842, 78.21, 3, 71.99, 76.67, 100, 57.97],
      [1588174895816, 88, 3, 84.58, 83.33, 100, 80.68],
      [1588174894373, 99.84, 3, 99.82, 100, 100, 99.52],
      [1588174893734, 81.76, 3, 79.48, 76.67, 100, 68.61],
      [1588174897289, 56.17, 3, 43.64, 40, 100, 28.5],
      [1588174897698, 80.92, 3, 75.46, 83.33, 100, 59.42],
      [1588174896374, 100, 3, 100, 100, 100, 100],
      [1588174895182, 100, 3, 100, 100, 100, 100],
      [1588174897042, 88.57, 3, 85.3, 100, 100, 65.7],
      [1588174897289, 60.06, 3, 48.64, 40, 100, 40.17],
      [1588174894158, 80.47, 3, 78.03, 76.67, 100, 64.73],
      [1588174893774, 79.23, 3, 76.64, 76.67, 100, 61.03],
      [1588174894728, 97.62, 3, 97.32, 93.33, 100, 99.52],
      [1588174895006, 81.08, 3, 75.67, 83.33, 100, 59.9],
      [1588174894887, 59.1, 3, 58.24, 70, 66, 41.3],
      [1588174897289, 60.4, 3, 49.09, 40, 100, 41.2],
      [1588174894323, 91.1, 3, 92.37, 93.33, 80.95, 99.03],
      [1588174897225, 56.9, 3, 44.59, 63.33, 100, 7.38],
      [1588174896048, 78.05, 3, 71.78, 76.67, 100, 57.49],
      [1588174942953, 91.64, 3, 89.98, 80, 100, 94.93],
      [1588174788522, 100, 1, 100, 100, null, null],
      [1588174935534, 51.88, 3, 42.26, 10, 100, 45.65],
      [1588174955810, 59.5, 3, 47.92, 23.33, 100, 55.16],
      [1588174973577, 95.48, 3, 94.18, 100, 100, 86.43],
      [1588174955633, 96.71, 3, 96.06, 100, 100, 90.14],
      [1588174955515, 44.37, 3, 40.55, 56.67, 75, 1.45],
      [1588174788522, 5.56, 3, 6.25, 16.67, 0, 0],
      [1588174788522, 11.67, 3, 14, 35, 0, 0],
      [1588174955510, 49.85, 3, 35.52, 40, 100, 9.55],
      [1588174931361, 36.91, 3, 24.29, 10, 100, 0.72],
      [1588174963432, 79.58, 3, 73.75, 83.33, 100, 55.41],
      [1588174964117, 74.91, 3, 67.75, 76.67, 100, 48.07],
      [1588174965246, 97.62, 3, 97.32, 93.33, 100, 99.52],
      [1588174964120, 76.15, 3, 69.34, 70, 100, 58.45],
      [1588174964120, 76.15, 3, 69.34, 70, 100, 58.45],
      [1588174964658, 69.64, 3, 60.97, 83.33, 100, 25.6],
      [1588174965918, 61.55, 3, 50.56, 60, 100, 24.64],
      [1588174963433, 66.81, 3, 57.33, 70, 100, 30.43],
      [1588174968913, 62.49, 3, 51.78, 63.33, 100, 24.15],
      [1588174967506, 68.46, 3, 64.51, 70, 100, 35.37],
      [1588174968069, 82.99, 3, 80.87, 76.67, 100, 72.31],
      [1588174968482, 61.38, 3, 50.35, 60, 100, 24.15],
      [1588174963282, 68.14, 3, 67.04, 70, 76.92, 57.49],
      [1588174968045, 85.54, 3, 83.73, 83.33, 100, 73.28],
      [1588174965678, 99.27, 3, 99.24, 100, 99.53, 98.29],
      [1588174963262, 97.62, 3, 97.32, 93.33, 100, 99.52],
      [1588174963262, 97.62, 3, 97.32, 93.33, 100, 99.52],
      [1588174963262, 97.62, 3, 97.32, 93.33, 100, 99.52],
      [1588174965012, 100, 3, 100, 100, 100, 100],
      [1588174966213, 72.49, 3, 64.63, 93.33, 100, 24.15],
      [1588174967707, 71.88, 3, 68.37, 70, 100, 45.64],
      [1588174965011, 67.42, 3, 58.12, 76.67, 100, 25.6],
      [1588174966680, 66.94, 3, 57.49, 76.67, 100, 24.15],
      [1588174968882, 62.49, 3, 51.78, 63.33, 100, 24.15],
      [1588174964886, 93.87, 3, 93.11, 83.33, 100, 98.29],
      [1588174962743, 80.15, 3, 74.47, 76.67, 100, 63.77],
      [1588174965851, 59.16, 3, 47.49, 53.33, 100, 24.15],
      [1588174964201, 68.14, 3, 67.04, 70, 76.92, 57.49],
      [1588174968682, 49.65, 3, 47.76, 60, 64.81, 24.15],
      [1588174963799, 70.36, 3, 69.54, 76.67, 76.92, 57.49],
      [1588174966370, 64.72, 3, 54.64, 70, 100, 24.15],
      [1588174965037, 67.57, 3, 58.3, 70, 100, 32.7],
      [1588174966761, 62.21, 3, 51.41, 40, 100, 46.62],
      [1588174967104, 56.94, 3, 44.64, 46.67, 100, 24.15],
      [1588174964598, 94, 3, 93.25, 86.67, 100, 95.33],
      [1588174968233, 82.99, 3, 80.87, 76.67, 100, 72.31],
      [1588174968560, 59.32, 3, 47.7, 53.33, 100, 24.64],
      [1588174968400, 66.94, 3, 57.49, 76.67, 100, 24.15],
      [1588174967356, 67.1, 3, 57.7, 76.67, 100, 24.64],
      [1588174966826, 66.94, 3, 57.49, 76.67, 100, 24.15],
      [1588174964119, 100, 3, 100, 100, 100, 100],
      [1588174967463, 100, 3, 100, 100, 100, 100],
      [1588174964579, 92.6, 3, 92.07, 93.33, 96.88, 87.6],
      [1588174964678, 96.38, 3, 95.35, 93.33, 100, 95.81],
      [1588174966071, 86.34, 3, 82.44, 93.33, 100, 65.7],
      [1588174967748, 80.15, 3, 77.66, 76.67, 100, 63.77],
      [1588174966982, 59.16, 3, 61.78, 70, 50, 57.49],
      [1588174966982, 59.16, 3, 61.78, 70, 50, 57.49],
      [1588174963254, 79.22, 3, 76.75, 76.67, 98.98, 62],
      [1588174968248, 85.38, 3, 83.55, 83.33, 100, 72.8],
      [1588174965433, 97.45, 3, 97.14, 93.33, 100, 99.03],
      [1588174966205, 69.16, 3, 60.35, 83.33, 100, 24.15],
      [1588174963797, 67.1, 3, 57.7, 76.67, 100, 24.64],
      [1588174964408, 69.88, 3, 69.24, 76.67, 75, 57.97],
      [1588174967096, 67.21, 3, 67.13, 70, 67.86, 63.77],
      [1588174963972, 87.04, 3, 86.11, 100, 94.44, 66.67],
      [1588174966505, 68.16, 3, 59.06, 70, 100, 34.47],
      [1588174965724, 78.05, 3, 71.78, 76.67, 100, 57.49],
      [1588174965734, 80.31, 3, 74.68, 76.67, 100, 64.25],
      [1588174954530, 93.27, 3, 91.34, 83.33, 100, 96.47],
      [1588174953449, 74.59, 3, 67.33, 76.67, 100, 47.1],
      [1588174954040, 97.62, 3, 97.32, 93.33, 100, 99.52],
      [1588174954168, 73.77, 3, 66.27, 63.33, 100, 57.97],
      [1588174954168, 73.61, 3, 66.07, 63.33, 100, 57.49],
      [1588174953644, 53.12, 3, 54.02, 76.67, 50, 32.7],
      [1588174954647, 55.04, 3, 42.19, 40, 100, 25.12],
      [1588174954736, 77.46, 3, 71.02, 66.67, 100, 65.7],
      [1588174954798, 87.84, 3, 84.37, 83.33, 100, 80.19],
      [1588174935873, 92.57, 3, 91.64, 80, 100, 97.7],
      [1588174952518, 99.55, 3, 99.5, 100, 100, 98.66],
      [1588174953507, 100, 3, 100, 100, 100, 100],
      [1588174954419, 100, 3, 100, 100, 100, 100],
      [1588174953134, 49.95, 3, 49.94, 76.67, 50, 23.19],
      [1588174954373, 61.22, 3, 64.43, 76.67, 50, 57],
      [1588174952504, 63.68, 3, 62.27, 60, 75, 56.04],
      [1588174935958, 86.5, 3, 84.82, 83.33, 100, 76.18],
      [1588174952755, 93.43, 3, 92.61, 83.33, 100, 96.95],
      [1588174952257, 66.97, 3, 57.54, 70, 100, 30.92],
      [1588174955307, 45.2, 3, 43.83, 60, 50, 25.6],
      [1588174955033, 88.16, 3, 84.78, 83.33, 100, 81.16],
      [1588174953990, 56.65, 3, 44.26, 40, 100, 29.95],
      [1588174953850, 97.62, 3, 97.32, 93.33, 100, 99.52],
      [1588174955152, 80.59, 3, 75.05, 83.33, 100, 58.45],
      [1588174953468, 100, 3, 100, 100, 100, 100],
      [1588174952206, 86.18, 3, 82.24, 93.33, 100, 65.22],
      [1588174972691, 86.18, 3, 82.24, 93.33, 100, 65.22],
      [1588174955393, 97.45, 3, 96.73, 93.33, 100, 99.03],
      [1588174952827, 78.41, 3, 75.71, 70, 100, 65.22],
      [1588174972815, 79.27, 3, 77.06, 76.67, 96.88, 64.25],
      [1588174953919, 97.62, 3, 97.32, 93.33, 100, 99.52],
      [1588174952584, 97.62, 3, 97.32, 93.33, 100, 99.52],
      [1588174953129, 56.46, 3, 58.3, 63.33, 50, 56.04],
      [1588174955110, 82.69, 3, 77.74, 83.33, 100, 64.73],
      [1588174935554, 92.85, 3, 91.96, 80, 100, 98.55],
      [1588174935714, 92.85, 3, 91.96, 80, 100, 98.55],
      [1588174935752, 92.85, 3, 91.96, 80, 100, 98.55],
      [1588174936203, 87.29, 3, 85.7, 63.33, 100, 98.55],
      [1588174901407, 92.46, 3, 90.31, 83.33, 100, 94.06],
      [1588174901644, 76.97, 3, 70.39, 83.33, 100, 47.58],
      [1588174901523, 69.73, 3, 69.79, 76.67, 69.23, 63.29],
      [1588174899081, 99.52, 3, 99.46, 100, 100, 98.55],
      [1588174904243, 77.76, 3, 71.41, 70, 100, 63.29],
      [1588174902236, 72.68, 3, 72.84, 83.33, 71.43, 63.29],
      [1588174900829, 54.9, 3, 56.3, 76.67, 50, 38.02],
      [1588174902047, 55.09, 3, 42.26, 46.67, 100, 18.6],
      [1588174904204, 64.43, 3, 54.27, 46.67, 100, 46.62],
      [1588174903437, 52.4, 3, 53.09, 70, 50, 37.2],
      [1588174902938, 69.03, 3, 60.19, 76.67, 100, 30.43],
      [1588174900053, 62.02, 3, 61.09, 53.33, 69.44, 63.29],
      [1588174900143, 74.94, 3, 72.12, 83.33, 97.5, 43.99],
      [1588174901034, 85.21, 3, 83.37, 83.33, 100, 72.31],
      [1588174903342, 72.21, 3, 64.27, 53.33, 100, 63.29],
      [1588174899583, 80.56, 3, 78.52, 76.67, 96.88, 68.13],
      [1588174898983, 91.95, 3, 90.94, 100, 100, 75.84],
      [1588174899902, 93.96, 3, 93.2, 83.33, 100, 98.55],
      [1588174899458, 100, 3, 100, 100, 100, 100],
      [1588174900303, 87.76, 3, 84.27, 100, 100, 63.29],
      [1588174900376, 80.66, 3, 78.55, 100, 97.5, 44.47],
      [1588174898821, 50.09, 3, 46.98, 40, 75, 35.27],
      [1588174904836, 77.3, 3, 74.46, 66.67, 100, 65.22],
      [1588174902344, 48.79, 3, 48.45, 76.67, 50, 19.71],
      [1588174898560, 97.05, 3, 96.68, 93.33, 100, 97.81],
      [1588174903645, 68.87, 3, 59.98, 76.67, 100, 29.95],
      [1588174904416, 57.76, 3, 59.98, 60, 50, 63.29],
      [1588174903003, 68.64, 3, 67.84, 66.67, 75, 64.25],
      [1588174901380, 85.21, 3, 80.99, 83.33, 100, 72.31],
      [1588174902712, 62.98, 3, 52.41, 40, 100, 48.95],
      [1588174901929, 45.09, 3, 43.68, 83.33, 50, 1.93],
      [1588174899252, 97.71, 3, 97.64, 100, 98.28, 94.85],
      [1588174900830, 85.05, 3, 83.19, 83.33, 100, 71.83],
      [1588174901855, 82.21, 3, 77.12, 83.33, 100, 63.29],
      [1588174903575, 100, 3, 100, 100, 100, 100],
      [1588174899151, 100, 3, 100, 100, 100, 100],
      [1588174901764, 89.67, 3, 91.3, 93.33, 76.66, 99.03],
      [1588174903834, 88.41, 3, 85.09, 100, 100, 65.22],
      [1588174900200, 77.58, 3, 78.59, 100, 69.44, 63.29],
      [1588174902359, 99.52, 3, 99.38, 100, 100, 98.55],
      [1588174903124, 80.3, 3, 78.14, 100, 97.62, 43.29],
      [1588174900157, 75.35, 3, 76.09, 93.33, 69.44, 63.29],
      [1588174900587, 80.31, 3, 77.84, 76.67, 100, 64.25],
      [1588174901141, 85.21, 3, 83.37, 83.33, 100, 72.31],
      [1588174900712, 100, 3, 100, 100, 100, 100],
      [1588174898672, 70.93, 3, 62.63, 83.33, 100, 29.47],
      [1588174903822, 71.1, 3, 77.12, 100, 50, 63.29],
      [1588174899722, 67.35, 3, 66.19, 83.33, 76.66, 42.07],
      [1588174904064, 66.81, 3, 66.83, 83.33, 66.66, 50.45],
      [1588174904549, 99.68, 3, 99.64, 100, 100, 99.03],
      [1588174904544, 58.01, 3, 46.01, 63.33, 100, 10.7],
      [1588174902938, 68.87, 3, 59.98, 76.67, 100, 29.95],
      [1588174902463, 39.37, 3, 36.34, 66.67, 50, 1.45],
      [1588174903083, 79.99, 3, 74.27, 76.67, 100, 63.29],
      [1588174972428, 93.59, 3, 91.76, 83.33, 100, 97.44],
      [1588174971707, 72.37, 3, 64.47, 76.67, 100, 40.43],
      [1588174969040, 72.22, 3, 64.28, 83.33, 100, 33.33],
      [1588174970621, 45.66, 3, 44.42, 76.67, 50, 10.32],
      [1588174971497, 74.75, 3, 67.54, 66.67, 100, 57.58],
      [1588174969373, 50.2, 3, 50.25, 70, 50, 30.59],
      [1588174970070, 77.84, 3, 75.07, 70, 100, 63.51],
      [1588174969528, 99.7, 3, 99.73, 100, 99.45, 99.65],
      [1588174970437, 99.65, 3, 99.6, 100, 100, 98.94],
      [1588174971940, 56.87, 3, 58.83, 93.33, 50, 27.27],
      [1588174972128, 38.25, 3, 34.89, 63.33, 50, 1.42],
      [1588174970476, 93.19, 3, 92.34, 83.33, 100, 96.23],
      [1588174969140, 80.4, 3, 74.8, 76.67, 100, 64.54],
      [1588174972071, 37.14, 3, 33.47, 60, 50, 1.42],
      [1588174970994, 40.59, 3, 37.9, 70, 50, 1.77],
      [1588174971885, 83.7, 3, 79.04, 83.33, 100, 67.77],
      [1588174971875, 54, 3, 40.85, 40, 100, 21.99],
      [1588174969799, 77.84, 3, 75.07, 70, 100, 63.51],
      [1588174969618, 100, 3, 100, 100, 100, 100],
      [1588174971221, 96.31, 3, 95.25, 93.33, 100, 95.59],
      [1588174970748, 97.42, 3, 96.69, 93.33, 100, 98.94],
      [1588174970255, 73.5, 3, 70.97, 70, 93.75, 56.74],
      [1588174969888, 77.72, 3, 74.94, 70, 100, 63.16],
      [1588174969272, 40.47, 3, 37.75, 70, 50, 1.42],
      [1588174970989, 40.35, 3, 37.6, 70, 50, 1.06],
      [1588174969752, 45.35, 3, 42.9, 70, 65, 1.06],
      [1588174972398, 70.5, 3, 70.66, 76.67, 69.23, 65.6],
      [1588174970366, 91.64, 3, 92.3, 93.33, 86.37, 95.23],
      [1588174971594, 56, 3, 43.43, 63.33, 100, 4.67],
      [1588174971501, 73.24, 3, 65.59, 63.33, 100, 56.38],
      [1588174945918, 94.22, 3, 92.57, 86.67, 100, 95.99],
      [1588174947442, 68.26, 3, 59.2, 86.67, 100, 18.12],
      [1588174946710, 97.45, 3, 97.14, 93.33, 100, 99.03],
      [1588174946176, 46.83, 3, 45.92, 80, 50, 10.48],
      [1588174947116, 68.21, 3, 59.13, 46.67, 100, 57.97],
      [1588174946487, 99.52, 3, 99.59, 100, 100, 98.55],
      [1588174946340, 99.68, 3, 99.64, 100, 100, 99.03],
      [1588174946686, 43.19, 3, 41.24, 76.67, 50, 2.9],
      [1588174947547, 59.53, 3, 47.97, 76.67, 100, 1.93],
      [1588174946873, 99.43, 3, 99.36, 100, 100, 98.29],
      [1588174947168, 90.13, 3, 87.31, 83.33, 100, 87.07],
      [1588174834489, 19.37, 3, 24.91, 56.67, 0, 1.45],
      [1588174946226, 86.18, 3, 82.24, 93.33, 100, 65.22],
      [1588174945996, 86.02, 3, 84.27, 93.33, 100, 64.73],
      [1588174947286, 92.85, 3, 91.96, 80, 100, 98.55],
      [1588174947366, 59.69, 3, 48.18, 73.33, 100, 5.75],
      [1588174947366, 59.69, 3, 48.18, 73.33, 100, 5.75],
      [1588174915059, 90.89, 3, 88.28, 76.67, 100, 95.99],
      [1588174915660, 77.45, 3, 71.01, 83.33, 100, 49.03],
      [1588174918126, 92.85, 3, 91.96, 80, 100, 98.55],
      [1588174919356, 76.02, 3, 69.17, 63.33, 100, 64.73],
      [1588174919356, 76.18, 3, 69.38, 63.33, 100, 65.22],
      [1588174918725, 62.22, 3, 51.43, 76.67, 100, 10],
      [1588174920762, 70.15, 3, 61.62, 46.67, 100, 63.77],
      [1588174918783, 69.89, 3, 61.28, 66.67, 100, 42.99],
      [1588174919853, 59.37, 3, 47.77, 76.67, 100, 1.45],
      [1588174920457, 78.57, 3, 72.44, 70, 100, 65.7],
      [1588174917227, 72.04, 3, 68.55, 76.67, 100, 39.46],
      [1588174917622, 67.31, 3, 57.97, 66.67, 100, 35.27],
      [1588174915722, 72.04, 3, 68.55, 76.67, 100, 39.46],
      [1588174915487, 96.9, 3, 96.51, 100, 100, 90.7],
      [1588174914909, 99.68, 3, 99.64, 100, 100, 99.03],
      [1588174915883, 100, 3, 100, 100, 100, 100],
      [1588174918439, 88.08, 3, 84.68, 100, 100, 64.25],
      [1588174915294, 81.29, 3, 75.95, 83.33, 100, 60.55],
      [1588174920002, 72.87, 3, 65.11, 83.33, 100, 35.27],
      [1588174920317, 69.03, 3, 60.19, 76.67, 100, 30.43],
      [1588174916162, 93.87, 3, 93.11, 83.33, 100, 98.29],
      [1588174915622, 82.53, 3, 77.53, 83.33, 100, 64.25],
      [1588174918535, 74.75, 3, 67.54, 60, 100, 64.25],
      [1588174917756, 62.87, 3, 58.23, 53.33, 100, 35.27],
      [1588174919554, 72.87, 3, 65.11, 83.33, 100, 35.27],
      [1588174916163, 85.7, 3, 81.61, 83.33, 100, 73.76],
      [1588174919347, 56.81, 3, 44.47, 40, 100, 30.43],
      [1588174917929, 80.15, 3, 74.47, 76.67, 100, 63.77],
      [1588174916166, 97.45, 3, 97.14, 93.33, 100, 99.03],
      [1588174917335, 72.04, 3, 68.55, 76.67, 100, 39.46],
      [1588174917940, 56.2, 3, 43.69, 66.67, 100, 1.93],
      [1588174920269, 65.46, 3, 55.59, 83.33, 100, 13.04],
      [1588174919851, 82.53, 3, 77.53, 83.33, 100, 64.25],
      [1588174915397, 100, 3, 100, 100, 100, 100],
      [1588174916293, 100, 3, 100, 100, 100, 100],
      [1588174916630, 89.1, 3, 91.58, 100, 69.23, 98.07],
      [1588174916620, 86.34, 3, 82.44, 93.33, 100, 65.7],
      [1588174918318, 99.68, 3, 99.58, 100, 100, 99.03],
      [1588174919140, 51.59, 3, 52.05, 70, 50, 34.78],
      [1588174919140, 51.59, 3, 52.05, 70, 50, 34.78],
      [1588174916758, 79.17, 3, 76.93, 76.67, 97.06, 63.77],
      [1588174916908, 72.04, 3, 68.55, 76.67, 100, 39.46],
      [1588174919090, 75.09, 3, 71.97, 73.33, 100, 51.93],
      [1588174919362, 78.25, 3, 72.04, 83.33, 100, 51.43],
      [1588174917034, 82.53, 3, 77.53, 83.33, 100, 64.25],
      [1588174917451, 69.48, 3, 65.94, 76.67, 97.83, 33.94],
      [1588174920038, 67.63, 3, 67.43, 76.67, 69.23, 57],
      [1588174917097, 97.29, 3, 96.95, 93.33, 100, 98.55],
      [1588174917477, 70.24, 3, 61.73, 76.67, 100, 34.04],
      [1588174917477, 70.24, 3, 61.73, 76.67, 100, 34.04],
      [1588174920525, 90.1, 3, 87.27, 83.33, 100, 86.96],
      [1588174918257, 82.53, 3, 77.53, 83.33, 100, 64.25],
      [1588174956823, 65.41, 3, 61.09, 93.33, 100, 2.9],
      [1588174956694, 89.52, 3, 88.21, 70, 100, 98.55],
      [1588174957037, 92.85, 3, 91.96, 80, 100, 98.55],
      [1588174957552, 60.64, 3, 55.72, 80, 100, 1.93],
      [1588174957334, 61.75, 3, 50.83, 83.33, 100, 1.93],
      [1588174956945, 62.71, 3, 58.05, 86.67, 100, 1.45],
      [1588174957185, 43.77, 3, 41.99, 40, 50, 41.3],
      [1588174957558, 67.99, 3, 67.83, 70, 69.23, 64.73],
      [1588174931226, 80.02, 3, 74.31, 80, 100, 60.06],
      [1588174945638, 74.72, 3, 67.49, 83.33, 100, 40.82],
      [1588174943975, 99.52, 3, 99.46, 100, 100, 98.55],
      [1588174943621, 43.65, 3, 41.84, 70, 50, 10.96],
      [1588174944269, 68.24, 3, 59.17, 40, 100, 64.73],
      [1588174944104, 74.91, 3, 67.74, 60, 100, 64.73],
      [1588174944647, 70.93, 3, 67.3, 40, 100, 72.8],
      [1588174944507, 55.91, 3, 43.31, 60, 100, 7.73],
      [1588174812928, 36.04, 3, 34.3, 56.67, 50, 1.45],
      [1588174943342, 98.44, 3, 98.25, 100, 100, 95.33],
      [1588174945249, 100, 3, 100, 100, 100, 100],
      [1588174944041, 100, 3, 100, 100, 100, 100],
      [1588174945546, 85.7, 3, 81.61, 93.33, 100, 63.77],
      [1588174945313, 93.87, 3, 93.11, 83.33, 100, 98.29],
      [1588174945838, 71.42, 3, 63.25, 83.33, 100, 30.92],
      [1588174944360, 83.16, 3, 78.34, 76.67, 100, 72.8],
      [1588174944556, 80.47, 3, 74.89, 76.67, 100, 64.73],
      [1588174943303, 100, 3, 100, 100, 100, 100],
      [1588174943476, 73.98, 3, 70.73, 86.67, 100, 35.27],
      [1588174943833, 86.18, 3, 82.24, 93.33, 100, 65.22],
      [1588174944749, 77.42, 3, 74.84, 70, 98, 64.25],
      [1588174945332, 93.01, 3, 92.14, 80, 100, 99.03],
      [1588174945631, 71.25, 3, 63.04, 83.33, 100, 30.43],
      [1588174944921, 58.36, 3, 53.97, 70, 93.48, 11.59],
      [1588174945089, 89.89, 3, 91.4, 93.33, 77.78, 98.55],
      [1588174943477, 64.92, 3, 54.89, 56.67, 100, 38.08],
      [1588174942753, 53.51, 3, 40.23, 56.67, 100, 3.86],
      [1588174924515, 22.22, 3, 28.57, 33.33, 0, 33.33],
      [1588174897871, 72.87, 3, 65.11, 100, 100, 18.6],
      [1588174898198, 40.48, 3, 37.76, 70, 50, 1.45],
      [1588174836554, 27.22, 3, 35, 56.67, 0, 25],
      [1588174898099, 84.36, 3, 86.42, 86.67, 67.86, 98.55],
      [1588174898123, 59.74, 3, 48.24, 73.33, 100, 5.89],
      [1588174928107, 82.69, 3, 77.74, 100, 100, 48.07],
      [1588174928342, 92.85, 3, 91.96, 80, 100, 98.55],
      [1588174928014, 72.66, 3, 64.84, 60, 100, 57.97],
      [1588174926609, 49.05, 3, 48.78, 86.67, 50, 10.48],
      [1588174928608, 68.05, 3, 58.93, 46.67, 100, 57.49],
      [1588174928484, 72.66, 3, 64.84, 60, 100, 57.97],
      [1588174821625, 36.04, 3, 40.54, 73.33, 0, 34.78],
      [1588174926816, 77.73, 3, 74.95, 76.67, 100, 56.53],
      [1588174926754, 77.9, 3, 75.13, 76.67, 100, 57.02],
      [1588174927678, 97.29, 3, 96.73, 93.33, 100, 98.55],
      [1588174926660, 99.68, 3, 99.64, 100, 100, 99.03],
      [1588174821079, 36.04, 3, 40.54, 73.33, 0, 34.78],
      [1588174928864, 99.43, 3, 99.36, 100, 100, 98.29],
      [1588174927801, 88.31, 3, 84.97, 76.67, 100, 88.26],
      [1588174927347, 77.9, 3, 75.13, 76.67, 100, 57.02],
      [1588174928393, 78.21, 3, 71.99, 76.67, 100, 57.97],
      [1588174928840, 100, 3, 100, 100, 100, 100],
      [1588174927135, 100, 3, 100, 100, 100, 100],
      [1588174929132, 86.34, 3, 82.44, 93.33, 100, 65.7],
      [1588174927181, 86.02, 3, 84.27, 93.33, 100, 64.73],
      [1588174926998, 80.12, 3, 77.63, 83.33, 100, 57.02],
      [1588174927434, 84.09, 3, 82.1, 93.33, 100, 58.94],
      [1588174928142, 66.75, 3, 65.72, 73.33, 75, 51.93],
      [1588174929057, 60.69, 3, 49.46, 73.33, 100, 8.74],
      [1588174928486, 83.61, 3, 78.92, 93.33, 100, 57.49],
      [1588174918472, 81.64, 3, 83.2, 76.67, 69.23, 99.03],
      [1588174917529, 91.74, 3, 90.7, 93.33, 100, 81.88],
      [1588174917709, 97.45, 3, 97.14, 93.33, 100, 99.03],
      [1588174917731, 100, 3, 100, 100, 100, 100],
      [1588174917580, 77.3, 3, 74.46, 66.67, 100, 65.22],
      [1588174917176, 99.43, 3, 99.36, 100, 100, 98.29],
      [1588174918073, 65.14, 3, 55.18, 83.33, 100, 12.08],
      [1588174917832, 100, 3, 100, 100, 100, 100],
      [1588174917367, 83.96, 3, 81.96, 86.67, 100, 65.22],
      [1588174917493, 99.52, 3, 99.46, 100, 100, 98.55],
      [1588174942546, 62.71, 3, 52.05, 86.67, 100, 1.45],
      [1588174942437, 99.03, 3, 99.17, 100, 100, 97.1],
      [1588174942437, 99.03, 3, 99.17, 100, 100, 97.1],
      [1588174936279, 95.97, 3, 95.47, 100, 100, 87.92],
      [1588174978600, 69.01, 3, 60.16, 70, 100, 37.04],
      [1588174914573, 43.2, 3, 41.26, 70, 50, 9.61],
      [1588174914486, 38.25, 3, 34.89, 63.33, 50, 1.42],
      [1588174913607, 73.81, 3, 66.32, 86.67, 100, 34.75],
      [1588174914115, 66.57, 3, 57.02, 76.67, 100, 23.05],
      [1588174914397, 60.35, 3, 49.03, 80, 100, 1.06],
      [1588174914263, 86.43, 3, 82.55, 93.33, 100, 65.96],
      [1588174913957, 95.2, 3, 93.83, 86.67, 100, 98.94],
      [1588174913971, 40.35, 3, 37.6, 70, 50, 1.06],
      [1588174914913, 46.03, 3, 44.9, 86.67, 50, 1.42],
      [1588174913745, 43.44, 3, 41.57, 70, 50, 10.32],
      [1588174914714, 72.84, 3, 65.08, 76.67, 100, 41.84],
      [1588174978755, 51.85, 3, 38.1, 46.67, 100, 8.89],
      [1588174933828, 77.45, 3, 71.01, 83.33, 100, 49.03],
      [1588174934062, 89.25, 3, 87.91, 100, 100, 67.75],
      [1588174931761, 71.9, 3, 63.87, 83.33, 100, 32.37],
      [1588174934565, 69.82, 3, 61.2, 70, 100, 39.46],
      [1588174931731, 74.75, 3, 67.54, 93.33, 100, 30.92],
      [1588174933396, 77.13, 3, 74.28, 66.67, 100, 64.73],
      [1588174932121, 99.84, 3, 99.82, 100, 100, 99.52],
      [1588174932232, 100, 3, 100, 100, 100, 100],
      [1588174933005, 100, 3, 100, 100, 100, 100],
      [1588174932366, 88.08, 3, 84.68, 100, 100, 64.25],
      [1588174931590, 93.87, 3, 93.11, 83.33, 100, 98.29],
      [1588174934223, 66.02, 3, 56.32, 66.67, 100, 31.4],
      [1588174933108, 53.98, 3, 40.83, 60, 100, 1.93],
      [1588174932639, 63.51, 3, 64, 66.67, 59.62, 64.25],
      [1588174933891, 61.74, 3, 50.8, 53.33, 100, 31.88],
      [1588174933529, 99.84, 3, 99.82, 100, 100, 99.52],
      [1588174933157, 77.3, 3, 74.46, 66.67, 100, 65.22],
      [1588174932777, 100, 3, 100, 100, 100, 100],
      [1588174934238, 96.65, 3, 95.69, 100, 100, 89.94],
      [1588174933705, 99.68, 3, 99.58, 100, 100, 99.03],
      [1588174932473, 82.69, 3, 77.74, 83.33, 100, 64.73],
      [1588174931889, 86.02, 3, 84.27, 93.33, 100, 64.73],
      [1588174933321, 82.69, 3, 80.52, 83.33, 100, 64.73],
      [1588174932797, 100, 3, 100, 100, 100, 100],
      [1588174931970, 76.97, 3, 74.09, 100, 100, 30.92],
      [1588174934383, 100, 3, 100, 100, 100, 100],
      [1588174934381, 63.26, 3, 52.76, 80, 100, 9.78],
      [1588174934912, 51.27, 3, 37.35, 53.33, 100, 0.48],
      [1588174935397, 49.05, 3, 34.49, 46.67, 100, 0.48],
      [1588174935193, 49.05, 3, 34.49, 46.67, 100, 0.48],
      [1588174935034, 63.5, 3, 53.07, 56.67, 100, 33.82],
      [1588174934855, 72.79, 3, 65.01, 80, 100, 38.36],
      [1588174949078, 79.45, 3, 79.45, 93.33, 79.41, 65.6],
      [1588174948346, 43.56, 3, 41.72, 70, 50, 10.67],
      [1588174949594, 71.89, 3, 63.86, 66.67, 100, 49.01],
      [1588174948461, 62.15, 3, 60.91, 86.67, 72.06, 27.73],
      [1588174949280, 78.43, 3, 75.73, 70, 100, 65.28],
      [1588174948196, 99.76, 3, 99.73, 100, 100, 99.29],
      [1588174947861, 99.88, 3, 99.87, 100, 100, 99.65],
      [1588174948730, 75.91, 3, 69.03, 93.33, 100, 34.4],
      [1588174948244, 99.43, 3, 99.36, 100, 100, 98.29],
      [1588174948639, 34.92, 3, 30.61, 53.33, 50, 1.42],
      [1588174948834, 46.03, 3, 44.9, 86.67, 50, 1.42],
      [1588174949749, 83.58, 3, 78.89, 83.33, 100, 67.41],
      [1588174949489, 95.32, 3, 94.73, 86.67, 100, 99.29],
      [1588174949415, 78.43, 3, 75.73, 70, 100, 65.28],
      [1588174948007, 86.19, 3, 82.25, 93.33, 100, 65.25],
      [1588174949927, 51.47, 3, 51.89, 70, 50, 34.4],
      [1588174948939, 74.61, 3, 71.66, 70, 98.15, 55.67],
      [1588174949495, 80.77, 3, 78.37, 76.67, 100, 65.64],
      [1588174950085, 48.25, 3, 47.75, 93.33, 50, 1.42],
      [1588174947666, 87.9, 3, 89.96, 93.33, 71.43, 98.94],
      [1588174947757, 58.35, 3, 46.45, 70, 100, 5.04],
      [1588174924173, 96.44, 3, 95.42, 93.33, 100, 95.99],
      [1588174923859, 73.43, 3, 75.66, 100, 55.55, 64.73],
      [1588174921616, 97.62, 3, 97.32, 93.33, 100, 99.52],
      [1588174925838, 69.07, 3, 60.23, 70, 100, 37.2],
      [1588174924652, 68.67, 3, 67.88, 93.33, 75, 37.68],
      [1588174798332, 22.22, 3, 28.57, 56.67, 0, 10],
      [1588174921196, 49.21, 3, 48.98, 86.67, 50, 10.96],
      [1588174925193, 57.13, 3, 44.89, 40, 100, 31.4],
      [1588174922328, 69.82, 3, 61.2, 53.33, 100, 56.13],
      [1588174920884, 46.04, 3, 44.91, 86.67, 50, 1.45],
      [1588174925015, 88.04, 3, 84.62, 76.67, 100, 87.44],
      [1588174921731, 61.66, 3, 57.56, 53.33, 94.44, 37.2],
      [1588174922673, 67.64, 3, 65.38, 86.67, 85.72, 30.53],
      [1588174922255, 83.32, 3, 81.23, 76.67, 100, 73.28],
      [1588174925401, 65.89, 3, 56.15, 60, 100, 37.68],
      [1588174924295, 83.48, 3, 81.41, 76.67, 100, 73.76],
      [1588174921307, 92.21, 3, 91.32, 93.33, 99.27, 84.02],
      [1588174923017, 99.68, 3, 99.64, 100, 100, 99.03],
      [1588174922070, 100, 3, 100, 100, 100, 100],
      [1588174923773, 74.78, 3, 67.58, 86.67, 100, 37.68],
      [1588174922388, 68.37, 3, 66.82, 86.67, 80.77, 37.68],
      [1588174926495, 75.07, 3, 71.96, 60, 100, 65.22],
      [1588174925451, 49.37, 3, 49.19, 70, 50, 28.11],
      [1588174920755, 93.59, 3, 92.79, 100, 100, 80.77],
      [1588174924785, 48.51, 3, 48.54, 60, 48.34, 37.2],
      [1588174923469, 79.85, 3, 74.1, 93.33, 100, 46.23],
      [1588174924451, 67.92, 3, 58.76, 40, 100, 63.77],
      [1588174924688, 56.68, 3, 58.59, 83.33, 50, 36.71],
      [1588174921475, 95.23, 3, 94.64, 86.67, 100, 99.03],
      [1588174922499, 83.48, 3, 81.41, 76.67, 100, 73.76],
      [1588174921642, 100, 3, 100, 100, 100, 100],
      [1588174921908, 100, 3, 100, 100, 100, 100],
      [1588174923378, 93.8, 3, 95.11, 100, 83.34, 98.07],
      [1588174926392, 94.42, 3, 92.83, 93.33, 100, 89.94],
      [1588174923789, 70.89, 3, 70.38, 100, 75, 37.68],
      [1588174924018, 99.52, 3, 99.38, 100, 100, 98.55],
      [1588174922867, 72.66, 3, 71.64, 100, 80.77, 37.2],
      [1588174921739, 68.88, 3, 68.81, 100, 69.44, 37.2],
      [1588174924636, 49.37, 3, 49.19, 63.33, 50, 34.78],
      [1588174921218, 82.92, 3, 81.05, 86.67, 97.83, 64.25],
      [1588174923005, 85.54, 3, 83.73, 83.33, 100, 73.28],
      [1588174923377, 93.15, 3, 93.08, 86.67, 93.75, 99.03],
      [1588174925531, 65.09, 3, 55.11, 93.33, 100, 1.93],
      [1588174926184, 48.42, 3, 47.97, 93.33, 50, 1.93],
      [1588174923587, 56.13, 3, 57.64, 86.67, 44.05, 37.68],
      [1588174925736, 68.97, 3, 69.34, 76.67, 66, 64.25],
      [1588174926450, 99.68, 3, 99.64, 100, 100, 99.03],
      [1588174931022, 50.24, 3, 40.29, 50, 100, 0.72],
      [1588174926403, 65.31, 3, 55.39, 86.67, 100, 9.25],
      [1588174925049, 87.92, 3, 84.46, 93.33, 100, 70.42],
      [1588174926006, 80.79, 3, 75.3, 76.67, 100, 65.7],
      [1588174977473, 70.85, 3, 67.21, 56.67, 100, 55.88],
      [1588174977333, 56.93, 3, 44.62, 46.67, 100, 24.11],
      [1588174956694, 90.4, 3, 87.66, 76.67, 100, 94.54],
      [1588174956485, 67.92, 3, 58.76, 40, 100, 63.77],
      [1588174956142, 76.97, 3, 70.39, 66.67, 100, 64.25],
      [1588174956078, 83.27, 3, 78.49, 63.33, 100, 86.47],
      [1588174955905, 93.11, 3, 91.14, 83.33, 100, 95.99],
      [1588174956263, 80.15, 3, 74.47, 76.67, 100, 63.77],
      [1588174956392, 85.33, 3, 81.14, 70, 100, 85.99],
      [1588174950376, 35.67, 3, 31.58, 56.67, 50, 0.35],
      [1588174951790, 77.18, 3, 70.66, 93.33, 100, 38.22],
      [1588174951047, 43.32, 3, 41.42, 70, 50, 9.97],
      [1588174950801, 50.2, 3, 50.25, 70, 50, 30.59],
      [1588174950420, 99.19, 3, 99.15, 100, 99.49, 98.08],
      [1588174950555, 85.04, 3, 83.17, 86.67, 100, 68.44],
      [1588174951212, 76.03, 3, 69.18, 93.33, 100, 34.75],
      [1588174950555, 62.81, 3, 58.16, 53.33, 100, 35.11],
      [1588174950555, 73.5, 3, 70.18, 70, 100, 50.49],
      [1588174950175, 75.91, 3, 69.03, 76.67, 100, 51.06],
      [1588174951489, 43.64, 3, 41.82, 60, 50, 20.92],
      [1588174951957, 38.25, 3, 34.89, 63.33, 50, 1.42],
      [1588174950555, 57.26, 3, 45.04, 70, 100, 1.77],
      [1588174951891, 100, 3, 100, 100, 100, 100],
      [1588174951392, 86.31, 3, 82.4, 93.33, 100, 65.6],
      [1588174951176, 82.7, 3, 77.75, 80, 100, 68.09],
      [1588174950555, 68.37, 3, 64.42, 70, 100, 35.11],
      [1588174950978, 40.59, 3, 37.9, 70, 50, 1.77],
      [1588174951734, 40.35, 3, 37.6, 70, 50, 1.06],
      [1588174950574, 92.52, 3, 93.43, 93.33, 85.3, 98.94],
      [1588174950716, 58.49, 3, 46.63, 70, 100, 5.46],
    ],
    matching: [
      'fetched',
      'score',
      'cardinal',
      'weightedScore',
      'structureScore',
      'dataprivacyScore',
      'uncategorizedScore',
    ],
    updated: 1588175228564,
  },
}
